import {
  ArrowLeft,
  ArrowRight,
  Star,
  StarHalf,
  StarOutline,
} from "@mui/icons-material";
import { Avatar, Box, Container, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import {Link} from 'react-router-dom';
import aidKit from "../../../Assets/Images/aidKit.png";
import rockStar from "../../../Assets/Images/rockStar.png";
import warmCore from "../../../Assets/Images/warmCore.png";
import Slider from "react-slick";
import "./styles.css";
import Image from "./Image";

const CarouselDesktop = ({ slidesToShow, events }) => {
  const [imageIndex, setImageIndex] = useState(0);

  const CDN_URL = process.env.REACT_APP_CDN_URL;

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <ArrowRight sx={{ color: "#df0c62" }} fontSize="large" />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <ArrowLeft sx={{ color: "#df0c62" }} fontSize="large" />
      </div>
    );
  };

  const settings = {
    pauseOnHover: true,
    pauseOnFocus: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    lazyLoad: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: slidesToShow,
    centerMode: false,
    centerPadding: 0,
    nextArrow: false,
    prevArrow: false,
    beforeChange: (current, next) => setImageIndex(next),
  };

  const imgArray = [warmCore, rockStar, warmCore, rockStar, warmCore];

  return (
    <Container sx={{ marginTop: "50px" }} maxWidth={"md"}>
      <Slider {...settings}>
      {events?.sort((a,b) => (a.isEnded > b.isEnded) ? 1 : (a.isEnded < b.isEnded) ? -1 : (a.startDate > b.startDate ? 1 : -1))?.map((it,ind) => (
        <div
          key={ind}
          className={ind === imageIndex ? "slide activeSlide" : "slide"}
        >
        <Link to={'event/'+it.slug}>
          {/* {card} */}
          <Image src={CDN_URL+it.image} />
          {/* <img src={CDN_URL+it.image} alt={ind} width="100%" height="100%" /> */}
          </Link>
        </div>
      ))}
      </Slider>
    </Container>
  );
};

export default CarouselDesktop;
