import { dataGet, dataPost } from "../Services/data.js";

export const addPayment = async (params) => {
  let payment = {};
  await dataPost("payment/addPayment", { ...params }).then((response) => {
    payment = response.content;
  });
  return payment;
};

export const listPayments = async () => {
  let paymentes = [];
  await dataGet("payment/getCustomerAll").then((response) => {
    if (response && response.isSuccess) paymentes = response.content;
  });
  return paymentes;
};

export const updatePayment = async (params) => {
  let payment = {};
  await dataPost("payment/updatePayment", { ...params }).then((response) => {
    payment = response.content;
  });
  return payment;
};

export const setDefault = async (id) => {
  let payment = {};
  await dataPost("payment/setDefault", { id: id }).then((response) => {
    payment = response.content;
  });
  return payment;
};

export const deletePayment = async (id) => {
  let payment = {};
  await dataPost("payment/deletePayment", { _id: id }).then((response) => {
    payment = response.content;
  });
  return payment;
};
