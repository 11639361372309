import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom'
import { toast } from "react-toastify";
import { listLatest, ticketCancel,onResend } from "../../../../../Data/order";
import { listAllDetailed } from "../../../../../Data/event";
import { ExpandMore, FileDownloadOutlined, Close, Email, Save  } from "@mui/icons-material";
import classes from "./styles.module.css";
import { BsTrash } from "react-icons/bs";
import SearchBar from "../../../SearchBar/SearchBar";
import AModal from "../../../Modal/AModal";

import { useTranslation } from "react-i18next";
const SaleDesktop = () => {

  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [allorders, setallOrders] = useState([]);
  const [events, setEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimer, setSearchTimer] = useState(null);
  const [showEnded, setShowEnded] = useState(false);
  const navigate = useNavigate();
  const CDN_URL = process.env.REACT_APP_CDN_URL;


  const fetch = async () => {
    if(searchQuery != "") setOrders(await listLatest(searchQuery));
    else setOrders(await listLatest());

    setEvents(await listAllDetailed());
  };

  const toggleEve = (ind) => {
    let evesList = [...events];
    let thiseveactive = evesList.findIndex((it) => it._id == ind);
    if(evesList[thiseveactive].active) evesList[thiseveactive].active = false;
    else evesList[thiseveactive].active = true;
    setEvents(evesList);
  }

  const onCancel = async (id) => {
    await ticketCancel(id).then((r) => {
      if (!r) toast.error(t('error_try_again'));
      else {
        toast.success(t('ticket_canceled'));
        fetch();
      }
    });
  };

  const onSearch = async(query) => {
    if(query == ""){
      setOrders(await listLatest());
      setSearchTimer(null);
    }else{
      if(searchTimer) clearTimeout(searchTimer);
      let timer = setTimeout(async() => {
        setOrders(await listLatest(query));
      },1500);
      setSearchTimer(timer);
    }
  }

  const search = (query) => {
    setSearchQuery(query);
    onSearch(query);
  };

  const onResendTickets = async (id,email) => {
    await onResend(id,email).then((r) => {
      if(!r) toast.error(t('error_try_again'));
      else toast.success(t('tickets_sent_again'));
    })
  }

  useEffect(() => {
    fetch();
  }, []);

  let ctmrArray = [0, 1];
  let customer = {};

  return (
    <Box>
      <Stack display="flex" direction="column" spacing={4}>
        {/* first Part */}

        <Stack
          display="flex"
          direction="column"
          padding={5}
          spacing={2}
          style={{minHeight:'fit-content!important',backgroundColor:'#ffd283',height:'fit-content!important'}}
          className={classes.scrollable}
        >
          <Typography variant="h5" color="#df0c62" fontWeight="bold">
            ETKİNLİKLER BİLGİLERİ
          </Typography>
          <Box
            sx={{
              backgroundColor: "#fff0d7",
            }}
          >
            <Stack style={{backgroundColor:'#ffd283'}} display="flex" direction="column" paddingTop="20px" spacing={2}>

              {events.filter((it) => !it.isEnded).length ? (
                <>
                  {events.filter((it) => !it.isEnded).map((eve, ind) => (
                    <Box key={ind} sx={{ borderBottom: "2px solid #df0c62" }}>
                      <Accordion
                        sx={{
                          backgroundColor: "#ffd283",
                          boxShadow: "none",
                        }}
                        onChange={() => toggleEve(eve._id)}
                        expanded={eve.active || false}
                      >
                        <AccordionSummary

                          sx={{ paddingRight: "0",textAlign:'center' }}
                          expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Stack style={{}} display="flex" direction="row" width="100%">
                            <Box flex={2} color="#df0c62">
                              <Typography style={{ textAlign:'start'}} fontWeight="bold">{eve.title}</Typography>
                            </Box>
                            <Box flex={2} color="#df0c62">
                              <Typography style={{ textAlign:'end'}} fontWeight="bold">{eve.isEnded ? 'Sona Erdi': 'Satışı Devam Ediyor'}</Typography>
                            </Box>

                            <Box flex={1} color="#df0c62">
                              <Typography fontWeight="bold" sx={{ paddingLeft: "4px", textAlign:'end' }}>
                                Satıldı: {eve.soldTickets}
                              </Typography>
                            </Box>
                            {/* End of Data Title */}
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>

                        <div className="container-fluid my-4" style={{color:'#df0c62'}}>
                        <hr />
                          <div className="row">
                            <div className="col-6 overall">
                            <img src={CDN_URL+eve.image} alt={eve.title} width="100%" />
                            </div>
                            <div className="col-6 stat">
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Tür: <strong style={{float:'right'}}>{eve.type}</strong></div>
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Şehir: <strong style={{float:'right'}}>{eve.city}</strong></div>
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Mekan: <strong style={{float:'right'}}>{eve.location}</strong></div>
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Organizatör: <strong style={{float:'right'}}>{eve.promoter ? eve.promoter.businessName : "eTicket Al"}</strong></div>
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Tarih: <strong style={{float:'right'}}>{eve.date?.split('T')[0]}</strong></div>
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Katılım: <strong style={{float:'right'}}>{eve.usedPercent}%</strong></div>
                            </div>
                          </div>
                        </div>

                        <div className="container-fluid my-4" style={{color:'#df0c62'}}>
                        <hr />
                          <div className="row">
                            <div className="col-6 overall">
                              <h5 style={{width:'100%'}}>Bilet türü:</h5>
                              {eve?.types?.map((ttype,tind) => (
                                <div key={tind} style={{width:'90%',marginLeft:'auto'}}>{ttype.title} - {ttype.price} {eve.currency} - <strong>{ttype.sold} ({ttype.sold*ttype.price} {eve.currency})</strong></div>
                              ))}
                              <hr />
                              <h5 style={{width:'100%'}}>Ödeme türü:</h5>
                              <div style={{width:'90%',marginLeft:'auto'}}>Kart - {eve.total.cardVal} - <strong>{eve.total.card} {eve.currency}</strong></div>
                              <div style={{width:'90%',marginLeft:'auto'}}>Nakit - {eve.total.cashVal} - <strong>{eve.total.cash} {eve.currency}</strong></div>

                            </div>
                            <div className="col-6 stat">
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Satış / Katılım: <strong style={{float:'right'}}>{eve.soldToUsed.sold} / {eve.soldToUsed.used}</strong></div>
                              <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Toplam Kazanç: <strong style={{float:'right'}}>{eve.total.total} {eve.currency}</strong></div>
                              <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Platform Kazançı ({eve.commission}%):  <strong style={{float:'right'}}>{eve.total.earning} {eve.currency}</strong></div>
                              <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Organizatör Kazançı:  <strong style={{float:'right'}}>{eve.total.proearn} {eve.currency}</strong></div>
                            </div>
                          </div>
                          <hr />
                        </div>

                        </AccordionDetails>
                      </Accordion>
                      {/* Accordion End */}
                    </Box>
                  ))}
                </>
              ) : null}

              {showEnded && events.filter((it) => it.isEnded).length ? (
                <>

                  {events.filter((it) => it.isEnded).map((eve, ind) => (
                    <Box key={ind+(events.filter((it) => !it.isEnded).length)} sx={{ borderBottom: "2px solid #df0c62" }}>
                      <Accordion
                        sx={{
                          backgroundColor: "#ffd283",
                          boxShadow: "none",
                        }}
                        onChange={() => toggleEve(eve._id)}
                        expanded={eve.active || false}
                      >
                        <AccordionSummary

                          sx={{ paddingRight: "0",textAlign:'center' }}
                          expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Stack style={{}} display="flex" direction="row" width="100%">
                            <Box flex={2} color="#df0c62">
                              <Typography style={{ textAlign:'start'}} fontWeight="bold">{eve.title}</Typography>
                            </Box>
                            <Box flex={2} color="#df0c62">
                              <Typography style={{ textAlign:'end'}} fontWeight="bold">{eve.isEnded ? 'Sona Erdi': 'Satışı Devam Ediyor'}</Typography>
                            </Box>

                            <Box flex={1} color="#df0c62">
                              <Typography fontWeight="bold" sx={{ paddingLeft: "4px", textAlign:'end' }}>
                                Satıldı: {eve.soldTickets}
                              </Typography>
                            </Box>
                            {/* End of Data Title */}
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>

                        <div className="container-fluid my-4" style={{color:'#df0c62'}}>
                        <hr />
                          <div className="row">
                            <div className="col-6 overall">
                            <img src={CDN_URL+eve.image} alt={eve.title} width="100%" />
                            </div>
                            <div className="col-6 stat">
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Tür: <strong style={{float:'right'}}>{eve.type}</strong></div>
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Şehir: <strong style={{float:'right'}}>{eve.city}</strong></div>
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Mekan: <strong style={{float:'right'}}>{eve.location}</strong></div>
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Organizatör: <strong style={{float:'right'}}>{eve.promoter ? eve.promoter.businessName : "eTicket Al"}</strong></div>
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Tarih: <strong style={{float:'right'}}>{eve.date?.split('T')[0]}</strong></div>
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Katılım: <strong style={{float:'right'}}>{eve.usedPercent}%</strong></div>
                            </div>
                          </div>
                        </div>

                        <div className="container-fluid my-4" style={{color:'#df0c62'}}>
                        <hr />
                          <div className="row">
                            <div className="col-6 overall">
                              <h5 style={{width:'100%'}}>Bilet türü:</h5>
                              {eve?.types?.map((ttype,tind) => (
                                <div key={tind} style={{width:'90%',marginLeft:'auto'}}>{ttype.title} - {ttype.price} {eve.currency} - <strong>{ttype.sold} ({ttype.sold*ttype.price} {eve.currency})</strong></div>
                              ))}
                              <hr />
                              <h5 style={{width:'100%'}}>Ödeme türü:</h5>
                              <div style={{width:'90%',marginLeft:'auto'}}>Kart - {eve.total?.cardVal} - <strong>{eve.total?.card} {eve.currency}</strong></div>
                              <div style={{width:'90%',marginLeft:'auto'}}>Nakit - {eve.total?.cashVal} - <strong>{eve.total?.cash} {eve.currency}</strong></div>

                            </div>
                            <div className="col-6 stat">
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Satış / Katılım: <strong style={{float:'right'}}>{eve.soldToUsed?.sold} / {eve.soldToUsed?.used}</strong></div>
                              <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Toplam Kazanç: <strong style={{float:'right'}}>{eve.total?.total} {eve.currency}</strong></div>
                              <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Platform Kazançı ({eve.commission}%):  <strong style={{float:'right'}}>{eve.total?.earning} {eve.currency}</strong></div>
                              <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Organizatör Kazançı:  <strong style={{float:'right'}}>{eve.total?.proearn} {eve.currency}</strong></div>
                            </div>
                          </div>
                          <hr />
                        </div>

                        </AccordionDetails>
                      </Accordion>
                      {/* Accordion End */}
                    </Box>
                  ))}
                </>
              ) : null}
              {(events?.filter((it) => it.isEnded)?.length) ?
                 <div onClick={() => {setShowEnded(!showEnded)}} style={{cursor:'pointer', color: '#df0c62', width:'fit-content', marginLeft:'auto' }}>{showEnded ? "Geçmişleri Gizle" : "Geçmişleri Göster"}</div>
              : null}



            </Stack>

          </Box>
          {/* End Of Organizer Fee Information */}
        </Stack>

        {/* Search Bar */}
        <SearchBar query={searchQuery} changeQuery={search} />


        {/* ÜYE BİLGİLERİ */}
        <Stack
          display="flex"
          direction="column"
          padding="2%"
          spacing={2}
          sx={{ backgroundColor: "#ffd283", height: "fit-content" }}
          className={classes.member}
        >
          {/* Title */}
          <Typography variant="h5" fontWeight="bold" color="#df0c62">
            {searchQuery != '' ? "Bulunan Sonuçlar":"Son Siparişler"}
          </Typography>

          {orders.map((c, inx) => {
            return (
              <Box key={inx}>
                <Accordion
                  sx={{
                    backgroundColor: "#fff0d7",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    sx={{ paddingRight: "0" }}
                    expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Stack display="flex" direction="row" width="100%">
                      <Typography color="#df0c62" fontWeight="bold">
                      <>
                        {c.customer ? (
                          <>{c.customer.firstName + " " + c.customer.lastName}</>
                        ) : c.guest ? (
                          <>{c.guest.firstName + " " + c.guest.lastName}</>
                        ):null}
                        {" ("}{c.tickets.length}{" Adet)"}  {c.tickets[0].event?.currency.symbol}{c.total}
                        </>
                      </Typography>
                      <Box flex={1} sx={{ borderBottom: "2px solid #feb659" }}>
                        <Typography
                          color="#df0c62"
                          fontWeight="bold"
                          textAlign="end"
                        >
                          Biglileri Gör
                        </Typography>
                      </Box>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Detail parent Stack */}
                    <Stack display="flex" direction="column" spacing={4}>
                      {/* First Part Stack */}
                      <Stack
                        display="flex"
                        direction="row"
                        spacing={2}
                        sx={{ backgroundColor: "#fff0d7" }}
                      >
                        {/* Left Side */}
                        <Box flex={1}>
                          <Stack display="flex" direction="column" spacing={2}>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.customer?.firstName || c.guest?.firstName}
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.customer?.phoneNumber || c.guest?.phoneNumber}
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                Toplam {c.tickets[0].event?.currency.symbol}{c.total} {c.payment == 'cash' ? " (Nakit)" : " (Kredi Karti)"}
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                Ödeme Numarası: <a target="_blank" href={"https://dashboard.stripe.com/payments/"+c.securityKey?.split("_secret_")[0]}>{c.securityKey?.split("_secret_")[0]}</a>
                              </Typography>
                            </Box>

                          </Stack>
                        </Box>
                        {/* Right Side */}
                        <Box flex={1}>
                          <Stack display="flex" direction="column" spacing={2}>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.customer?.lastName || c.guest?.lastName}
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.customer?.email || c.guest?.email}
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.created?.split('T')[0] + " " + c.created?.split('T')[1].split('.')[0]}
                              </Typography>
                            </Box>
                            {c.invoiceAddress ? (
                              <Box
                                padding="8px 0"
                                sx={{ borderBottom: "2px solid #feb659" }}
                              >
                                <Typography color="#df0c62" fontWeight="bold">
                                  Fatura Adresi: <span>{c.invoiceAddress.address}, {c.invoiceAddress.addressContinue}, {c.invoiceAddress.city}, {c.invoiceAddress.country}</span>
                                </Typography>
                              </Box>
                            ):null}

                          </Stack>
                        </Box>
                        {/* End Of First Part Stack */}
                      </Stack>

                      <Stack display="flex" direction="column" spacing={2}>
                      <Typography color="#df0c62" variant="h5" fontWeight="bold" >
                        Etkinlik
                      </Typography>
                      <Stack display="flex" direction="column" spacing={2}>
                        <Box
                          padding="8px 0"
                          sx={{ borderBottom: "2px solid #feb659",marginTop:'10px',display:'flex',alignItems:'center',justifyContent:'space-between' }}
                        >
                        <Typography color="#df0c62" fontWeight="bold">
                        {c.tickets[0].event.title} at {c.tickets[0].event.location?.name}
                        </Typography>
                        {c.tickets[0].event.promoter ? (
                          <Typography color="#df0c62" fontWeight="bold">
                          {c.tickets[0].event.promoter.firstName+" "+c.tickets[0].event.promoter.lastName}
                          </Typography>
                        ):null}

                        </Box>
                        </Stack>
                      </Stack>

                      <Stack display="flex" direction="column" spacing={2}>
                      <div className="d-flex flex-row align-items-center justify-content-between">
                      <Typography color="#df0c62" variant="h5" fontWeight="bold" >
                        Biletler
                      </Typography>
                      <div  className="centeredFlex d-inline-flex" style={c.tickets.find((it) => it.canceled) ? {opacity: 0.7,display:'inline-flex'}:{display:'inline-flex'}} >
                      <input className="simpleInput me-3" type='text' value={c.emailToSend} placeholder={c.guest?.email || c.customer?.email} onChange={(e) => {let tc = [...orders]; tc[inx].emailToSend = e.target.value; setOrders(tc); }}/>
                      <div className="centeredFlex d-inline-flex" onClick={() => {
                        if (window.confirm("Biletleri göndermek istediğinizde emin misiniz?")) onResendTickets(c._id,c.emailToSend);
                      }}>
                        <Typography color="#df0c62" fontWeight="bold">
                          Mail Gönder
                        </Typography>
                        <IconButton size="medium">
                          <Email sx={{ color: "#df0c62" }} />
                        </IconButton>
                        </div>

                        </div>
                        </div>
                      {c.tickets.map((ticket,tindex) => (
                        <div style={ticket.canceled ? {opacity:0.4}:null}>
                        <Box
                          padding="8px 0"
                          sx={{ borderBottom: "2px solid #feb659",marginTop:'10px',display:'flex',alignItems:'center',justifyContent:'space-between' }}
                        >
                          <Typography color="#df0c62" fontWeight="bold">
                          <span style={ticket.canceled ? {textDecoration:'line-through'}:null}>
                            {ticket.event.plan.startsFromOne ? ticket.trueLabel : ticket.label } - {ticket.event.currency.symbol+ticket.price}
                            </span>
                          </Typography>

                          {ticket.canceled ? (<Typography color="#df0c62" fontWeight="bold">
                            Iptal Edildi
                          </Typography>):null}
                          <div>
                          <a href={process.env.REACT_APP_CDN_URL+'pdfs/'+ticket._id+'.pdf'} target="_blank" className="centeredFlex d-inline-flex me-4" style={c.tickets.find((it) => it.canceled) ? {pointerEvents:'none',display:'inline-flex'}:{display:'inline-flex'}} >
                            <Typography color="#df0c62" fontWeight="bold">
                              Bilet İndir
                            </Typography>
                            <IconButton size="medium">
                              <Save sx={{ color: "#df0c62" }} />
                            </IconButton>

                            </a>
                          <div onClick={() => {
                            if (window.confirm("Bilet sılıyorsunuz. Emin misiniz?")) onCancel(ticket._id)
                          }} className="centeredFlex" style={ticket.canceled ? {pointerEvents:'none',display:'inline-flex'}:{display:'inline-flex'}} >
                            <Typography color="#df0c62" fontWeight="bold">
                              Iptal Et
                            </Typography>
                            <IconButton size="medium">
                              <Close sx={{ color: "#df0c62" }} />
                            </IconButton>

                            </div>
                            </div>
                        </Box>
                        </div>
                      ))}

                      </Stack>
                      {/* End Of Accordion Detail Stack */}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                {/* Accordion End */}
              </Box>
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
};

export default SaleDesktop;
