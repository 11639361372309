import {
  Add,
  Close,
  ExpandMore,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Grid,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { slideInRight } from "animate.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../../SearchBar/SearchBar";
import Charts from "../../../Stats/Charts";
import classes from "./styles.module.css";
import LocationSubmit from "../../../OrganizerPanel/LocationSubmit";
import CurrentEvent from "../../../OrganizerPanel/Organizer/Desktop/Expanding";
import EventSubmit from "../../../OrganizerPanel/EventSubmit";
import SponsorTemplate from "../../../AdminPanel/Sponsor/SponsorTemplate/SponsorTemplate";
import TemplateOne from "../../Sponsor/SponsorTemplate/TemplateOne";
import TemplateTwo from "../../Sponsor/SponsorTemplate/TemplateTwo";
import TemplateThree from "../../Sponsor/SponsorTemplate/TemplateThree";
import TemplateFour from "../../Sponsor/SponsorTemplate/TemplateFour";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { standartSeats, defaultType, standartMap } from "../../../../../Services/helpers";


import { getDashboard } from "../../../../../Data/admin";
import { addEvent, updateEvent, deleteEvent } from "../../../../../Data/event";
import {listLocations, addLocation, updateLocation, deleteLocation} from "../../../../../Data/location";
import { saveImage } from "../../../../../Data/general";

const AdminDesktop = () => {
  const { t } = useTranslation();
  const eventProto = {plan:{isSimple:true,map:standartMap,standartSeatTypes:[...standartSeats]}};
  const [eventmodal, setEventModal] = useState(false);
  const [locations, setLocations] = useState([]);

  const [fieldmodal, setFieldModal] = useState(false);
  const [fieldForm, setFieldForm] = useState({ plan: { simple: true, map: standartMap, standartSeatTypes: [...standartSeats] } });
  const [expandedEvents, setExpandedEvents] = useState(false);
  const [expandedEndedEvents, setExpandedEndedEvents] = useState(false);
  const [expandedLocations, setExpandedLocations] = useState(false);
  const [eventForm, setEventForm] = useState(eventProto);
  const [dashboard, setDashboard] = useState(null);
  const [pendingApiCall, setPendingApiCall] = useState(false)

  const onEventClose = () => {
    setEventModal(false);
  };

  const editEvent = (eve) => {
    setEventForm(eve);
    setEventModal(true);
  }

  const editLocation = (loc) => {
    setFieldForm(loc);
    setFieldModal(true);
  }

  const onFieldClose = () => {
    setFieldModal(false);
  };

  const onFieldDelete = async (_id) => {
    if(window.confirm('Deleting location. Are you sure?')){
      await deleteLocation(_id).then((r) => {
        if(!r) toast.error(t('error_try_again'));
      });
    }
  }

  const onFieldSubmit = async () => {
    let _location = {
      ...fieldForm,
      isApproved: true
    }
    new Promise(async(resolve,reject) => {
      console.log(fieldForm.image);
      if(!fieldForm.image){
        resolve(1);
      }else if(typeof(fieldForm.image) == "string" || fieldForm.image instanceof String){
        resolve(1);
      }else{
        await saveImage(fieldForm.image).then(async (imgUrl) => {
          if(imgUrl){
            _location.image = imgUrl;
            resolve(1);
          }else reject();
        });
      }
    }).then(async(r) => {
      await updateLocation(_location).then(async(location) => {
        if (!location) toast.error(t('error_try_again'));
        else {
          setFieldModal(false);
          setFieldForm({});
          setLocations(await listLocations());
          toast.success(t('location_updated'));
        }
      });
    }).catch((e) => {
      toast.error(t('error_try_again'));
    });
  };

  const uploadImages = async () => {
    let imgs = {first: null, second: null};
    let url = "",smurl = "";

    if(eventForm.image){
      if(typeof eventForm.image == 'string') url = eventForm.image;
      else await saveImage(eventForm.image,'portrait').then((imgUrl) => { if(imgUrl) url = imgUrl; });
    }else url = "";

    if(eventForm.smimage){
      if(typeof eventForm.smimage == 'string') smurl = eventForm.smimage;
      else await saveImage(eventForm.smimage).then((imgUrl) => { if(imgUrl) smurl = imgUrl; });
    }else smurl = "";

    imgs = {first: url, second: smurl};
    return imgs;
  }

  const onEventSubmit = async () => {
    setPendingApiCall(true)
    await uploadImages().then(async(imgs) => {
      let _event = {...eventForm,isApproved: true };
      if(imgs.first) _event.image = imgs.first;
      if(imgs.second) _event.smimage = imgs.second;

      if(_event._id){
         await updateEvent(_event).then((__event) => {
           if(!__event){
             setPendingApiCall(false);
             toast.error(t('error_try_again'));
           }
           else{
             setEventModal(false);
             setEventForm({});
             fetchDashboard();
             setPendingApiCall(false)
             toast.success(t('event_updated'));
           }
        });
      }else{
        await addEvent(_event).then((__event) => {
          if(!__event){
            setPendingApiCall(false);
            toast.error(t('error_try_again'));
          }
          else{
            setEventModal(false);
            setEventForm({});
            fetchDashboard();
            setPendingApiCall(false)
            toast.success(t('event_added'));
          }
        });
      }
    });

  };

  const delEvent = async (id) => {
    await deleteEvent(id).then((r) => {
      if(r){
        toast.success(t('event_deleted'));
        setEventForm({plan:{isSimple:true,map:standartMap,standartSeatTypes:[...standartSeats]}});
        onEventClose();
        fetchDashboard();
      }else toast.error(t('error_try_again'));
    })
  }

  const [sponsorModal, setSponsorModal] = useState(false);
  const onSponsorclose = () => {
    setSponsorModal(false);
  };
  const navigate = useNavigate();

  const fetchDashboard = async () => {
    setDashboard(await getDashboard());
    setLocations(await listLocations());
  }

  useEffect(() => {
    fetchDashboard();
  }, []);

  const accordArray = [0, 1, 2, 3];
  const templates = [
    <TemplateOne />,
    <TemplateTwo />,
    <TemplateThree />,
    <TemplateFour />,
  ];
  return (
    <Box>
      <Modal open={eventmodal} onClose={onEventClose}>
        <EventSubmit
          form={eventForm}
          setForm={setEventForm}
          submit={onEventSubmit}
          onDismiss={onEventClose}
          isAdmin={true}
          delEvent={delEvent}
          pendingApiCall={pendingApiCall}
        />
      </Modal>

      <Modal open={fieldmodal} onClose={onFieldClose}>
        <LocationSubmit
          form={fieldForm}
          setForm={setFieldForm}
          submit={onFieldSubmit}
          onDismiss={onFieldClose}
        />
      </Modal>

      <Modal
        sx={{
          overflowY: "scroll",
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5%",
        }}
        open={sponsorModal}
        onClose={onSponsorclose}
      >
        <Box sx={{ height: "100%" }}>
          <Container
            maxWidth="xl"
            sx={{
              height: "80vh",
              backgroundColor: "#fff0d7",
              overflowY: "scroll",
              padding: "32px 0",
            }}
            className={classes.containerScroll}
          >
            <Stack display="flex" direction="column" spacing={2}>
              <Box display="flex" justifyContent="end">
                <IconButton size="large" onClick={() => setSponsorModal(false)}>
                  <Close fontSize="large" sx={{ color: "#df0c62" }} />
                </IconButton>
              </Box>
              {accordArray.map((ar, indx) => {
                return (
                  <Accordion
                    key={indx}
                    sx={{
                      overflowY: "scroll",
                      backgroundColor: "transparent",
                      borderBottom: "2px solid #df0c62",
                    }}

                    className={classes.accordionScroll}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        color="#df0c62"
                        fontWeight="bold"
                        variant="h6"
                      >
                        Sponsor {indx + 1}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SponsorTemplate Children={templates[indx]} />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Stack>
          </Container>
        </Box>
      </Modal>

      {/* Parent Stack */}
      <Stack display="flex" direction="column" spacing={4}>
        <Stack
          display="flex"
          direction="row"
          sx={{
            width: "100%",
            backgroundColor: "#ffd283",
          }}
          spacing={2}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#feb659",
              "&:hover": {
                backgroundColor: "#feb659",
                boxShadow: "0px 0px 5px 1px #df0c62",
              },
            }}
            disableElevation
            onClick={() => {
              setEventForm({...eventProto});
              setEventModal(true);
            }}
          >
            <Add />
          </Button>
          <Box
            flex={1}
            sx={{
              backgroundColor: "#df0c62",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              padding: "8px",
            }}
          >
            <Typography color="white">YENİ ETKİNLİK OLUŞTUR</Typography>
          </Box>
          {/* End Of First Part */}
        </Stack>

        {/* Second Part */}
        <Stack
          display="flex"
          direction="column"
          sx={{
            backgroundColor: "#fff0d7",
            padding: "24px 16px",
            boxShadow: "-3px -3px 3px 0px #df0c62",
          }}
          spacing={1}
        >
          <Typography variant="h5" color="#df0c62" fontWeight="bold">
            GENEL BİLGİLER
          </Typography>

          <Stack display="flex" direction="row">
            <Box>
              <Typography color="#df0c62" fontWeight="bold">
                Aktif Etkinlik Sayısı:
              </Typography>
            </Box>
            <Box flex={2} sx={{ borderBottom: "2px solid #feb659" }}>
              <Typography color="#df0c62" textAlign="end">
                {dashboard?.statistics.activeEvents}
              </Typography>
            </Box>
          </Stack>
          <Stack display="flex" direction="row">
            <Box>
              <Typography color="#df0c62" fontWeight="bold">
                Organizatör Etkinlik sayısı:
              </Typography>
            </Box>
            <Box flex={2} sx={{ borderBottom: "2px solid #feb659" }}>
              <Typography color="#df0c62" textAlign="end">
                {dashboard?.statistics.promoterEvents}
              </Typography>
            </Box>
          </Stack>
          <Stack display="flex" direction="row">
            <Box>
              <Typography color="#df0c62" fontWeight="bold">
                Etkinlik Talepleri:
              </Typography>
            </Box>
            <Box flex={2} sx={{ borderBottom: "2px solid #feb659" }}>
              <Typography color="#df0c62" textAlign="end">
                {dashboard?.statistics.unApprovedEvents}
              </Typography>
            </Box>
          </Stack>
          <Stack display="flex" direction="row">
            <Box>
              <Typography color="#df0c62" fontWeight="bold">
                Ödeme Talepleri:
              </Typography>
            </Box>
            <Box flex={2} sx={{ borderBottom: "2px solid #feb659" }}>
              <Typography color="#df0c62" textAlign="end">
                {dashboard?.statistics.paymentRequests}
              </Typography>
            </Box>
          </Stack>
        </Stack>

        {/* Third Part */}
        <div className="d-none">
          <Typography variant="h5" color="#df0c62" fontWeight="bold">
            ETKİNLİK ARA
          </Typography>
          {/* Search Bar */}
          <SearchBar />

        </div>

        {/* Current Events */}
        <Stack
          display="flex"
          direction="column"
          sx={{ backgroundColor: "#fff0d7", padding: "32px" }}
        >
          <Typography variant="h5" color="#df0c62" fontWeight="bold">
            EN YAKIN ETKİNLİKLER
          </Typography>
          {/* Beginning Of Accordions */}
          {(expandedEvents ? dashboard?.events.filter((it) => !it.isEnded) : dashboard?.events.filter((it) => !it.isEnded)?.slice(0,5))?.map((ar, ind) => {
            return (
              <Accordion
                key={ind}
                sx={{
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #feb659",
                }}
              >
                <AccordionSummary
                  expandIcon={<VisibilityOutlined sx={{ color: "#feb659" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography fontWeight="bold" color="#df0c62">
                    {ar.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CurrentEvent refetch={fetchDashboard} e_event={ar} edit={editEvent} />
                </AccordionDetails>
              </Accordion>
            );
          })}

          {dashboard && dashboard.events.filter((it) => !it.isEnded) && dashboard.events.filter((it) => !it.isEnded).length > 5 ? (
            <Box className="mt-2 text-end cursor-pointer" onClick={() => setExpandedEvents(!expandedEvents)} >
              <Typography variant="h7" fontWeight="bold" color="#df0c62">
                Daha {expandedEvents ? "Az" : "Fazla"} Göster
              </Typography>
            </Box>
          ):null}



        </Stack>

        <Stack
          display="flex"
          direction="column"
          sx={{ backgroundColor: "#fff0d7", padding: "32px" }}
        >
          <Typography variant="h5" color="#df0c62" fontWeight="bold">
            GEÇMİŞ ETKİNLİKLER
          </Typography>
          {/* Beginning Of Accordions */}
          {(expandedEndedEvents ? dashboard?.events.filter((it) => it.isEnded) : dashboard?.events.filter((it) => it.isEnded)?.slice(0,5))?.map((ar, ind) => {
            return (
              <Accordion
                key={ind}
                sx={{
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #feb659",
                }}
              >
                <AccordionSummary
                  expandIcon={<VisibilityOutlined sx={{ color: "#feb659" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography fontWeight="bold" color="#df0c62">
                    {ar.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CurrentEvent refetch={fetchDashboard} e_event={ar} edit={editEvent} />
                </AccordionDetails>
              </Accordion>
            );
          })}

          {dashboard && dashboard.events && dashboard.events.filter((it) => it.isEnded).length > 5 ? (
            <Box className="mt-2 text-end cursor-pointer" onClick={() => setExpandedEndedEvents(!expandedEndedEvents)} >
              <Typography variant="h7" fontWeight="bold" color="#df0c62">
                Daha {expandedEndedEvents ? "Az" : "Fazla"} Göster
              </Typography>
            </Box>
          ):null}



        </Stack>

        <Stack
          display="flex"
          direction="column"
          sx={{ backgroundColor: "#fff0d7", padding: "32px" }}
        >
          <Typography variant="h5" color="#df0c62" fontWeight="bold">
            ETKINLİK MEKANLARI
          </Typography>
          {/* Beginning Of Accordions */}
          {(expandedLocations ? locations : locations.slice(0,5))?.map((ar, ind) => (
            <div key={ind} className='centeredFlex' style={{borderBottom: "2px solid #feb659",padding:'15px',justifyContent:'space-between'}}>
            <Typography fontWeight="bold" color="#df0c62">
              {ar.name}
            </Typography>
            <div className="centeredFlex">
            <Box className="ms-4 text-end cursor-pointer" onClick={() => editLocation(ar)} >
              <Typography variant="h7" fontWeight="bold" color="#df0c62">
                 Düzelt
              </Typography>
            </Box>
            <Box className="ms-4 text-end cursor-pointer" onClick={() => onFieldDelete(ar._id)} >
              <Typography variant="h7" fontWeight="bold" color="#df0c62">
                Sil
              </Typography>
            </Box>
            </div>
            </div>

          ))}
          {locations?.length > 5 ? (
            <Box className="mt-2 text-end cursor-pointer" onClick={() => setExpandedLocations(!expandedLocations)} >
              <Typography variant="h7" fontWeight="bold" color="#df0c62">
                Daha {expandedLocations ? "Az" : "Fazla"} Göster
              </Typography>
            </Box>
          ):null}


          {/*End of Fourth Part */}
        </Stack>

        {dashboard && dashboard.events && dashboard.events.length ? (
          <React.Fragment>
            <Stack display="flex" direction="row" justifyContent="space-between">
              <Typography variant="h5" fontWeight="bold" color="#df0c62">
                {"AYLIK GRAFİKLER"}
              </Typography>
              <Typography color="#df0c62" fontWeight="bold">

              </Typography>
            </Stack>

            <Grid container padding="8px 32px" sx={{ gap: { xs: 5, sm: 0 }, justifyContent:'center' }}>
            {dashboard.graphicStatistics.soldTickets != undefined ? (
              <Grid item xs={12} md={6}>
              <Stack
                display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  color="#df0c62"
                  sx={{ fontSize: { xs: "0.9rem", sm: "1.5rem" } }}
                >
                  {"SATILAN TÜM BİLETLER"}
                </Typography>
                <Box className={`${classes.circleBase} ${classes.circle2}`}>
                  <Box className={classes.innerCircle}>
                    <Typography variant="h6" fontWeight="bold" color="#df0c62">
                      {dashboard.graphicStatistics.soldTickets} Adet
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Grid>
          ):null}

              {dashboard.graphicStatistics.soldPromoterTickets != undefined ? (
                <Grid item xs={12} md={6}>
                <Stack
                  display="flex"
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  mt={{xs:4,lg:0}}
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color="#df0c62"
                    textAlign="center"
                    sx={{ fontSize: { xs: "0.9rem", sm: "1.5rem" } }}
                  >
                    {"ORGANİZATÖR BİLETLERİ"}
                  </Typography>
                  <Box className={`${classes.circleBase} ${classes.circle2}`}>
                    <Box className={classes.innerCircle}>
                      <Typography variant="h6" fontWeight="bold" color="#df0c62">
                        {dashboard.graphicStatistics.soldPromoterTickets} Adet
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </Grid>):null}

              {dashboard.graphicStatistics.totalEarning != undefined ? (
                <Grid item sm={12}>
                  <Stack
                    display="flex"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    mt={{xs:4,lg:0}}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      color="#df0c62"
                      sx={{ fontSize: { xs: "0.9rem", sm: "1.5rem" } }}
                    >
                      {"KAZANÇ"}
                    </Typography>
                    <Box className={`${classes.circleBase} ${classes.circle2}`}>
                      <Box className={classes.innerCircle}>
                        <Typography variant="h6" fontWeight="bold" color="#df0c62" align="center">
                        {''+ dashboard.graphicStatistics.totalEarning.usd + " USD"} <br />
                        {''+ dashboard.graphicStatistics.totalEarning.gbp + " GBP"} <br />
                        {''+ dashboard.graphicStatistics.totalEarning.cad + " CAD"} <br />
                        {''+ dashboard.graphicStatistics.totalEarning.eur + " EUR"} <br />
                        {''+ dashboard.graphicStatistics.totalEarning.try + " TRY"} <br />
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
              ):null}

            </Grid>
          </React.Fragment>
        ):null}

        {/* End Of Parent Stack */}
      </Stack>
      {/* Last Part */}
    </Box>
  );
};

export default AdminDesktop;
