import React, { forwardRef, useEffect, useRef, useState } from 'react';
import SeatchartJS, { Options } from 'seatchart';
import "./index.css";
import { useTranslation } from 'react-i18next';
import {MdImage,MdSchema,MdZoomIn,MdZoomOut} from "react-icons/md";
import {
  Modal
} from "@mui/material";

import {usePinch} from '@use-gesture/react';



const setForwardedRef = (ref, value) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}

const Seatchart = forwardRef(({ options, curr, planScheme, planImage }, ref) => {
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const seatchartRef = useRef();
  const elementRef = useRef(null);
  const [zoomState, setZoomState] = useState(0.8);
  const [schemeOpen, setSchemeOpen] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const { t } = useTranslation()
  const [scrollPosition, setScrollPosition] = useState({ top: 0, left: 0 });

  const initWheel = () => {
    //   const zoomElement = elementRef.current.querySelector('.sc-map');
    //   // zoomElement.style.overflow = 'scroll';
    //
    //   let zoom = 1;
    //   const ZOOM_SPEED = 0.01;
    //
    //   zoomElement.parentNode.addEventListener("wheel", function(e) {
    //     e.preventDefault();
    //
    //       if(e.deltaY > 0){
    //           zoomElement.style.transform = `scale(${zoom += ZOOM_SPEED})`;
    //       }else{
    //           zoomElement.style.transform = `scale(${zoom -= ZOOM_SPEED})`;  }
    //
    //   });
  }

  const translateCart = () => {
    setTimeout(() => {
      let cartstring = document.querySelector('.sc-cart-title');
      let totalstring = document.querySelector('.sc-cart-total');
      let reservedstring = document.querySelectorAll('.sc-legend-item');
      reservedstring = reservedstring[Array.from(reservedstring).length - 1].querySelector('.sc-legend-description');

      let cstring = t('cart');
      let tstring = t('total');
      let rstring = t('reserved');

      cartstring.innerHTML = cstring+" "+cartstring.innerHTML.split(' ')[1];
      totalstring.innerHTML = tstring+": "+totalstring.innerHTML.split(':')[1];
      reservedstring.innerHTML = rstring;

      document.querySelectorAll('.sc-front').forEach(element => {
        element.style.whiteSpace = 'nowrap';
        element.textContent = 'Stage '.repeat(5).trim();
        element.style.justifyContent = 'space-between';
        element.style.display = 'flex';
        element.innerHTML = element.textContent.split(' ').map(word => `<span>${word}</span>`).join('');
    });
    },50);

  }

  const zoomIn = () => {
    const map = elementRef.current.querySelector('.sc-map-inner-container');
    map.style.transform = 'scale(' + (zoomState + 0.1) + ')';

    const mapIndexer = elementRef.current.querySelector('.sc-indexer');
    mapIndexer.style.transform = 'scale(' + (zoomState + 0.1) + ')';
    setZoomState(zoomState + 0.1);
  }

  const zoomOut = () => {
    const map = elementRef.current.querySelector('.sc-map-inner-container');
    map.style.transform = 'scale(' + (zoomState - 0.1) + ')';

    const mapIndexer = elementRef.current.querySelector('.sc-indexer');
    mapIndexer.style.transform = 'scale(' + (zoomState - 0.1) + ')';
    setZoomState(zoomState - 0.1);
  }

  const handleScroll = (obj) => {
      const { scrollTop, scrollLeft } = elementRef.current.querySelector('.sc-map');
      setScrollPosition({ top: scrollTop, left: scrollLeft });
  }

  useEffect(() => {
    if (elementRef.current && !seatchartRef.current) {
      seatchartRef.current = new SeatchartJS(elementRef.current, { ...options, cart: { currency: curr } });

      setForwardedRef(ref, seatchartRef.current);

      initWheel();

      init();

      const map = elementRef.current.querySelector('.sc-map-inner-container');
      map.style.transform = 'scale(' + (zoomState) + ')';

      const mapIndexer = elementRef.current.querySelector('.sc-indexer');
      mapIndexer.style.transform = 'scale(' + (zoomState) + ')';

      const object = elementRef.current.querySelector('.sc-map');
      object.addEventListener('scroll', (e) => {handleScroll(e.target)});

      object.scrollTo({
        top: scrollPosition.top,
        left: scrollPosition.left
      });


      return () => {
        seatchartRef.current = undefined;
        setForwardedRef(ref, undefined);
        object.removeEventListener('scroll', (e) => {handleScroll(e.target)});

      };
    }
  }, [options]);

  const init = () => {
    document.querySelector('.seatPickerOuter').addEventListener('click',translateCart);
    translateCart();
    const object = elementRef.current.querySelector('.sc-map');
    object.scrollTo({
      top: object.scrollWidth - object.clientWidth,
      left: scrollPosition.left
    });
  }

  const bind = usePinch((state) => doSomethingWith(state), {});

  const doSomethingWith = (act) => {

    act.event.stopPropagation();

    const map = elementRef.current.querySelector('.sc-map-inner-container');
    const mapIndexer = elementRef.current.querySelector('.sc-indexer');

    let initTransVal = act.event.deltaY / 300 ;
    let transVal = initTransVal > 0.1 ? 0.1 : initTransVal;
    if(zoomState - transVal > 0.15 && zoomState - transVal < 1){
      map.style.transform = 'scale(' + (zoomState - transVal) + ')';
      mapIndexer.style.transform = 'scale(' + (zoomState - transVal) + ')';

      setZoomState(zoomState - transVal);
    }

  }

  return (
    <div className={"seatPickerOuter"+(options.inverted ? " inverted" : "")}>

    <Modal className="imageModal" open={schemeOpen} onClose={() => setSchemeOpen(false)}>
      <img src={CDN_URL + planScheme} />
    </Modal>
    <Modal className="imageModal" open={imageOpen} onClose={() => setImageOpen(false)}>
      <img src={CDN_URL + planImage} />
    </Modal>

      <div className="zoomBox">
      { planScheme && planScheme != '' ? (<div style={{userSelect: 'none'}} onClick={() => setSchemeOpen(true)}><MdSchema /> {t('planScheme')}</div>):null}
      { planImage && planImage != '' ? (<div style={{userSelect: 'none'}} onClick={() => setImageOpen(true)}><MdImage /> {t('planImage')}</div>):null}
        <div style={{userSelect: 'none'}} onClick={zoomIn}><MdZoomIn /> {t('Zoom In')}</div>
        <div style={{userSelect: 'none'}} onClick={zoomOut}><MdZoomOut /> {t('Zoom Out')}</div>
      </div>
      <div {...bind()} ref={elementRef} />
    </div>
  );
});

export default Seatchart;
