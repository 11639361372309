import {
  ExpandMore,
  FileDownloadOutlined,
  VisibilityOutlined,
  Close,
  Times
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Stack,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AModal from "../../../Modal/AModal";
import SearchBar from "../../../SearchBar/SearchBar";
import CurrentEvent from "../../../OrganizerPanel/Organizer/Desktop/Expanding";
import classes from "./styles.module.css";
import { listAll, saveOne, deletePromoter, listUnConfirmed, listConfirmed, confirm } from "../../../../../Data/promoter";
import { listNotApproved, listPromoted } from "../../../../../Data/event";
import { listOnlyPromoted, setComission } from "../../../../../Data/event";
import { addPayment, updatePayment, deletePayment } from "../../../../../Data/payment";
import { useTranslation } from "react-i18next";

const OrganizerDesktop = () => {
  const { t } = useTranslation();
  const ctmrArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const navigate = useNavigate();
  const defPayment = { amount: "", comment: "", visible: false };
  const [editingEvent, setEditingEvent] = useState(null);
  const [editingComission, setEditingComission] = useState(0);
  const [promoters, setPromoters] = useState([]);
  const [unComfirmed, setUnconfirmed] = useState([]);
  const [events, setEvents] = useState([]);
  const [requests, setRequests] = useState([]);
  const [promoted, setPromoted] = useState([]);

  const [targetPayment, setTargetPayment] = useState(defPayment);

  const fetch = async () => {
    setPromoters(await listConfirmed());
    setUnconfirmed(await listUnConfirmed());
    setEvents(await listOnlyPromoted());
    setRequests(await listNotApproved());

    let active = promoted.findIndex((it) => it.active == true);
    let plist = await listPromoted();
    if(active > -1) plist[active].active = true;
    setPromoted(plist);
  }

  const confirmPromoter = async (promoter) => {
    await confirm(promoter).then((r) => {
        if(r){
          toast.success(t('promoter_confirmed'));
          fetch();
        }else toast.error(t('error_try_again'))
    });
  }

  const submitComission = async (id, commission) => {
    await setComission(id, commission).then((r) => {
      if (r) { toast.success(t('commission_is_set')); fetch(); }
      else toast.error(t('error_try_again'));
    })
  }

  const commitPayment = async (eve) => {
    if (targetPayment._id) {
      await updatePayment(targetPayment).then((r) => {
        if (r) { toast.success(t('payment_updated')); fetch(); setTargetPayment(defPayment); }
        else toast.error(t('error_try_again'));
      });
    } else {
      await addPayment({ ...targetPayment, event: { _id: eve._id }, promoter: { _id: eve.promoter } }).then((r) => {
        if (r) { toast.success(t('payment_added')); fetch(); setTargetPayment(defPayment); }
        else toast.error(t('error_try_again'));
      });
    }
  };

  const toggleEve = (ind) => {
    let evesList = [...promoted];
    let thiseveactive = evesList[ind].active;
    evesList.map((it,ind) => evesList[ind].active = false);
    if(!thiseveactive) evesList[ind].active = true;
    // debugger;
    setPromoted(evesList);
  }

  const removePayment = async (payment) => {
    await deletePayment(payment).then((r) => {
      if (r) { toast.success(t('payment_deleted')); fetch(); }
      else toast.error(t('error_try_again'));
    });
  };


  const update = async (ind,type) => { await saveOne(type == 0 ? promoters[ind] : unComfirmed[ind]).then(() => { toast.success(t('promoter_updated')); }); }
  const create = async (rec) => { await saveOne(rec).then(() => { toast.success(t('promoter_added')); fetch(); }); }
  const deleteOne = async (id) => { if(window.confirm('Organizatör silmek istediğinizde emin misiniz?')) await deletePromoter(id).then(() => { toast.success(t('promoter_deleted')); fetch(); }); }
  useEffect(() => { fetch(); }, []);
  return (
    <React.Fragment>
      {/* Parent Stack */}
      <Stack display="flex" direction="column" spacing={4}>
        {/* Add ORganizor Modal*/}
        <AModal submit={create} title="ORGANİZATÖR EKLE" />
        {/* Request Stack */}
        <Stack
          display="flex"
          direction="column"
          padding={5}
          spacing={2}
          className={classes.scrollable}
        >
          <Typography variant="h6" color="#df0c62" fontWeight="bold">
            ÜYE BİLGİLERİ
          </Typography>

          {promoters.map((c, ind) => {
            return (
              <Accordion
              key={ind}
                sx={{
                  backgroundColor: "#fff0d7",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  sx={{ paddingRight: "0" }}
                  expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Stack display="flex" direction="row" width="100%">
                    <Typography color="#df0c62" fontWeight="bold">
                      {c.firstName + " " + c.lastName}
                    </Typography>
                    <Box flex={1} sx={{ borderBottom: "2px solid #feb659" }}>
                      <Typography
                        color="#df0c62"
                        fontWeight="bold"
                        textAlign="end"
                      >
                        Biglileri Gör
                      </Typography>
                    </Box>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Detail parent Stack */}
                  <Stack display="flex" direction="column" spacing={4}>
                    {/* First Part Stack */}
                    <Stack
                      display="flex"
                      direction="row"
                      spacing={2}
                      sx={{ backgroundColor: "#fff0d7" }}
                    >
                      {/* Left Side */}
                      <Box flex={1}>
                        <Stack display="flex" direction="column" spacing={2}>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...promoters]; list[ind]['firstName'] = e.target.value; setPromoters(list); }}
                              value={c.firstName}
                              variant="standard"
                              name="firstName"
                              placeholder="İsim"
                              type="text"
                            />
                          </Box>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...promoters]; list[ind]['phoneNumber'] = e.target.value; setPromoters(list); }}
                              value={c.phoneNumber}
                              variant="standard"
                              name="phone"
                              placeholder="Telefon"
                              type="text"
                            />
                          </Box>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              defaultValue={c.created.split('T')[0] + ' ' + c.created.split('T')[1].split('.')[0]}
                              variant="standard"
                              name="date"
                              placeholder="Üyelik tarihi"
                              type="text"
                            />
                          </Box>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          >
                            <span class="prefix">%</span>
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...promoters]; list[ind]['commission'] = e.target.value; setPromoters(list); }}
                              value={c.commission}
                              variant="standard"
                              name="commission"
                              placeholder="Komisyon"
                              type="text"
                            />
                          </Box>
                        </Stack>
                      </Box>
                      {/* Right Side */}
                      <Box flex={1}>
                        <Stack display="flex" direction="column" spacing={2}>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...promoters]; list[ind]['lastName'] = e.target.value; setPromoters(list); }}
                              value={c.lastName}
                              variant="standard"
                              name="surname"
                              placeholder="Soyisim"
                              type="text"
                            />
                          </Box>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...promoters]; list[ind]['email'] = e.target.value; setPromoters(list); }}
                              value={c.email}
                              variant="standard"
                              name="email"
                              placeholder="E-posta"
                              type="email"
                            />
                          </Box>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...promoters]; list[ind]['taxNumber'] = e.target.value; setPromoters(list); }}
                              value={c.taxNumber}
                              variant="standard"
                              name="tax number"
                              placeholder="Vergi Numarası"
                              type="text"
                            />
                          </Box>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...promoters]; list[ind]['businessName'] = e.target.value; setPromoters(list); }}
                              value={c.businessName}
                              variant="standard"
                              name="business name"
                              placeholder="Şirket İsmi"
                              type="text"
                            />
                          </Box>
                        </Stack>
                      </Box>
                      {/* End Of First Part Stack */}


                    </Stack>
                    {/* second Part Stack */}
                    <Stack display="flex" direction="column" spacing={2}>
                      <Box
                        onClick={() => { deleteOne(c._id); }}
                        sx={{
                          display: "flex",//flex
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <Typography color="#df0c62" fontWeight="bold">
                          Sil
                        </Typography>

                      </Box>
                      <Stack display="flex" direction="row" width="100%">
                        <Typography color="#df0c62" fontWeight="bold">

                        </Typography>
                        <Box
                          flex={1}
                          sx={{
                            borderBottom: "2px solid #feb659",
                            display: "none",//flex
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          <Typography color="#df0c62" fontWeight="bold">
                            PDF indir
                          </Typography>
                          <IconButton size="medium">
                            <FileDownloadOutlined sx={{ color: "#df0c62" }} />
                          </IconButton>
                        </Box>
                      </Stack>
                      {/* End of Second PArt Stack */}
                    </Stack>
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={() => { update(ind,0) }}
                      sx={{
                        backgroundColor: "#df0c62",
                        "&:hover": { backgroundColor: "#df0c62" },
                        borderRadius: "0",
                      }}
                    >
                      Bilgileri Kaydet
                    </Button>
                    {/* End Of Accordion Detail Stack */}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
          {/* End Of Request Stack */}
        </Stack>

        {/* Request Stack */}
        <Stack
          display="flex"
          direction="column"
          padding={5}
          spacing={2}
          className={classes.scrollable}
        >
          <Typography variant="h6" color="#df0c62" fontWeight="bold">
            ETKİNLİK TALEBİ
          </Typography>

          {requests.map((c, ind) => {
            return (
              <Stack
                key={ind}
                display="flex"
                direction="row"
                alignItems="center"
              >
                <Typography variant="h6" color="#df0c62">
                  {c.title}
                </Typography>
                <Box
                  flex={2}
                  sx={{
                    borderBottom: "2px solid #feb659",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Typography fontWeight="bold" color="#df0c62">
                    Talebi Görüntüle
                  </Typography>
                  <IconButton onClick={() => navigate("/admin/eventRequest/" + c._id)}>
                    <VisibilityOutlined sx={{ color: "#feb659" }} />
                  </IconButton>
                </Box>
              </Stack>
            );
          })}
          {/* End Of Request Stack */}
        </Stack>

        <Stack
          display="flex"
          direction="column"
          padding={5}
          spacing={2}
          className={classes.scrollable}
        >
          <Typography variant="h6" color="#df0c62" fontWeight="bold">
            ÜYE TALEPLERİ
          </Typography>

          {unComfirmed.map((c, ind) => {
            return (
              <Accordion
              key={ind}
                sx={{
                  backgroundColor: "#fff0d7",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  sx={{ paddingRight: "0" }}
                  expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Stack display="flex" direction="row" width="100%">
                    <Typography color="#df0c62" fontWeight="bold">
                      {c.firstName + " " + c.lastName}
                    </Typography>
                    <Box flex={1} sx={{ borderBottom: "2px solid #feb659" }}>
                      <Typography
                        color="#df0c62"
                        fontWeight="bold"
                        textAlign="end"
                      >
                        Biglileri Gör
                      </Typography>
                    </Box>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Detail parent Stack */}
                  <Stack display="flex" direction="column" spacing={4}>
                    {/* First Part Stack */}
                    <Stack
                      display="flex"
                      direction="row"
                      spacing={2}
                      sx={{ backgroundColor: "#fff0d7" }}
                    >
                      {/* Left Side */}
                      <Box flex={1}>
                        <Stack display="flex" direction="column" spacing={2}>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...unComfirmed]; list[ind]['firstName'] = e.target.value; setUnconfirmed(list); }}
                              value={c.firstName}
                              variant="standard"
                              name="firstName"
                              placeholder="İsim"
                              type="text"
                            />
                          </Box>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...unComfirmed]; list[ind]['phoneNumber'] = e.target.value; setUnconfirmed(list); }}
                              value={c.phoneNumber}
                              variant="standard"
                              name="phone"
                              placeholder="Telefon"
                              type="text"
                            />
                          </Box>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              defaultValue={c.created.split('T')[0] + ' ' + c.created.split('T')[1].split('.')[0]}
                              variant="standard"
                              name="date"
                              placeholder="Üyelik tarihi"
                              type="text"
                            />
                          </Box>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          >
                            <span class="prefix">%</span>
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...unComfirmed]; list[ind]['commission'] = e.target.value; setUnconfirmed(list); }}
                              value={c.commission}
                              variant="standard"
                              name="commission"
                              placeholder="Komisyon"
                              type="text"
                            />
                          </Box>
                        </Stack>
                      </Box>
                      {/* Right Side */}
                      <Box flex={1}>
                        <Stack display="flex" direction="column" spacing={2}>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...unComfirmed]; list[ind]['lastName'] = e.target.value; setUnconfirmed(list); }}
                              value={c.lastName}
                              variant="standard"
                              name="lastName"
                              placeholder="Soyisim"
                              type="text"
                            />
                          </Box>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...unComfirmed]; list[ind]['email'] = e.target.value; setUnconfirmed(list); }}
                              value={c.email}
                              variant="standard"
                              name="email"
                              placeholder="E-posta"
                              type="email"
                            />
                          </Box>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...unComfirmed]; list[ind]['taxNumber'] = e.target.value; setUnconfirmed(list); }}
                              value={c.taxNumber}
                              variant="standard"
                              name="tax number"
                              placeholder="Vergi Numarası"
                              type="text"
                            />
                          </Box>
                          <Box
                            padding="8px 0"
                            sx={{ borderBottom: "2px solid #feb659" }}
                          >
                            <input
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "#fff0d7",
                                width: "100%",
                              }}
                              onChange={(e) => { let list = [...unComfirmed]; list[ind]['businessName'] = e.target.value; setUnconfirmed(list); }}
                              value={c.businessName}
                              variant="standard"
                              name="businessName"
                              placeholder="Şirket İsmi"
                              type="text"
                            />
                          </Box>
                        </Stack>
                      </Box>
                      {/* End Of First Part Stack */}


                    </Stack>
                    {/* second Part Stack */}
                    <Stack sx={{marginLeft:'auto',textAlign:"right",justifyContent:'flex-end'}} display="flex" direction="row" spacing={4}>
                      <Box
                        onClick={() => { confirmPromoter(c); }}
                        sx={{
                          display: "flex",//flex
                          justifyContent: "end",
                          alignItems: "center",
                          cursor:'pointer'
                        }}
                      >
                        <Typography color="#df0c62" fontWeight="bold">
                          Onayla
                        </Typography>

                      </Box>
                      <Box
                        onClick={() => { deleteOne(c._id); }}
                        sx={{
                          display: "flex",//flex
                          justifyContent: "end",
                          alignItems: "center",
                          cursor:'pointer'
                        }}
                      >
                        <Typography color="#df0c62" fontWeight="bold">
                          Sil
                        </Typography>

                      </Box>
                      {/* End of Second PArt Stack */}
                    </Stack>
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={() => { update(ind,1) }}
                      sx={{
                        display:'none',
                        backgroundColor: "#df0c62",
                        "&:hover": { backgroundColor: "#df0c62" },
                        borderRadius: "0",
                      }}
                    >
                      Bilgileri Kaydet
                    </Button>
                    {/* End Of Accordion Detail Stack */}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
          {/* End Of Request Stack */}
        </Stack>

        {/* Organizer Fee Information */}
        <Stack
          display="flex"
          direction="column"
          padding={5}
          spacing={2}
          style={{minHeight:'fit-content!important',height:'fit-content!important', maxHeight:'90vh'}}
          className={classes.scrollable}
        >
          <Typography variant="h6" color="#df0c62" fontWeight="bold">
            ORGANİZATÖR ÜCRET BİLGİSİ
          </Typography>
          <Box
            sx={{
              backgroundColor: "#fff0d7",
            }}
          >
            <Stack display="flex" direction="column" paddingTop="20px" spacing={2}>


              <Stack
                display="flex"
                direction="row"
                sx={{ borderBottom: "2px solid #df0c62", padding: "0 16px" }}
              >
                <Box flex={2} color="#df0c62">
                  <Typography variant="h6" fontWeight="bold">
                    Etkinlik
                  </Typography>
                </Box>
                <Box flex={1} color="#df0c62">
                  <Typography variant="h6" fontWeight="bold">
                    Bilet
                  </Typography>
                </Box>
                <Box flex={1} color="#df0c62">
                  <Typography variant="h6" fontWeight="bold">
                    Komisyon
                  </Typography>
                </Box>
                <Box flex={1} color="#df0c62">
                  <Typography variant="h6" fontWeight="bold">
                    Kazanç
                  </Typography>
                </Box>
                <Box flex={1} color="#df0c62">
                  <Typography variant="h6" fontWeight="bold">
                    Organizatör
                  </Typography>
                </Box>
                <Box flex={1} color="#df0c62">
                  <Typography variant="h6" fontWeight="bold">
                    Ödenilecek
                  </Typography>
                </Box>
                <Box flex={1} color="#df0c62">
                  <Typography variant="h6" fontWeight="bold">
                    Gelir
                  </Typography>
                </Box>
                {/* End of Data Title */}
              </Stack>

              {promoted.length ? (
                <>
                  {promoted.map((eve, ind) => (
                    <Box key={ind} sx={{ borderBottom: "2px solid #df0c62" }}>
                      <Accordion
                        sx={{
                          backgroundColor: "#fff0d7",
                          boxShadow: "none",
                        }}
                        onChange={() => toggleEve(ind)}
                        expanded={eve.active || false}
                      >
                        <AccordionSummary

                          sx={{ paddingRight: "0" }}
                          expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Stack display="flex" direction="row" width="100%">
                            <Box flex={2} color="#df0c62">
                              <Typography fontWeight="bold">{eve.title}</Typography>
                            </Box>
                            <Box flex={1} color="#df0c62">
                              <Typography fontWeight="bold" sx={{ paddingLeft: "4px" }}>
                                {eve.soldTickets}
                              </Typography>
                            </Box>
                            <Box flex={1} color="#df0c62">
                              <Typography fontWeight="bold" sx={{ paddingLeft: "6px" }}>
                                {eve.commission}%
                              </Typography>
                            </Box>
                            <Box flex={1} color="#df0c62">
                              <Typography fontWeight="bold" sx={{ paddingLeft: "6px" }}>
                                {eve.total?.total} {eve.currency}
                              </Typography>
                            </Box>
                            <Box flex={1} color="#df0c62">
                              <Typography fontWeight="bold" sx={{ paddingLeft: "8px" }}>
                                {eve.total?.proearn} {eve.currency}
                              </Typography>
                            </Box>
                            <Box flex={1} color="#df0c62">
                              <Typography fontWeight="bold" sx={{ paddingLeft: "8px" }}>
                                {eve.total?.dept} {eve.currency}
                              </Typography>
                            </Box>
                            <Box flex={1} color="#df0c62">
                              <Typography fontWeight="bold" sx={{ paddingLeft: "8px" }}>
                                {eve.total?.earning} {eve.currency}
                              </Typography>
                            </Box>
                            {/* End of Data Title */}
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>

                        <div className="container-fluid my-4" style={{color:'#df0c62'}}>
                        <hr />
                          <div className="row">
                            <div className="col-6 overall">
                              <h5 style={{width:'100%'}}>Bilet türü:</h5>
                              {eve.types.map((ttype,tind) => (
                                <div key={tind} style={{width:'80%',marginLeft:'auto'}}>{ttype.title} - {ttype.price} {eve.currency} - <strong>{ttype.sold} ({ttype.sold*ttype.price} {eve.currency})</strong></div>
                              ))}
                              <hr />
                              <h5 style={{width:'100%'}}>Ödeme türü:</h5>
                              <div style={{width:'80%',marginLeft:'auto'}}>Kart - {eve.total.cardVal} - <strong>{eve.total.card} {eve.currency}</strong></div>
                              <div style={{width:'80%',marginLeft:'auto'}}>Nakit - {eve.total.cashVal} - <strong>{eve.total.cash} {eve.currency}</strong></div>

                            </div>
                            <div className="col-6 stat">
                            <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Satış / Katılım: <strong style={{float:'right'}}>{eve.soldToUsed.sold} / {eve.soldToUsed.used}</strong></div>
                              <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Toplam Kazanç: <strong style={{float:'right'}}>{eve.total.total} {eve.currency}</strong></div>
                              <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Organizatör Kazançı{eve.commission ? ' ('+(100 - eve.commission)+'%)' : null}:  <strong style={{float:'right'}}>{eve.total.proearn} {eve.currency}</strong></div>
                              <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Ödenilen Toplam: <strong style={{float:'right'}}>{eve.paymentsTotal || 0} {eve.currency}</strong></div>
                              <div style={{borderBottom: "1px solid #df0c62",width:'100%',height:'30px',marginTop:'5px'}}>Ödenilecek Miktar: <strong style={{float:'right'}}>{eve.total.dept} {eve.currency}</strong></div>
                            </div>
                          </div>
                          <hr />
                        </div>

                          <Stack display="flex" direction="row" spacing={2}>


                            {/* Left Side */}
                            <Box flex={1}>
                              <Stack display="flex" direction="column" spacing={1}>
                                <Stack
                                  display="flex"
                                  direction="row"
                                  sx={{
                                    border: "1px solid #df0c62",
                                    backgroundColor: "#df0c62",
                                  }}
                                >
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', padding: '0 3px' }}>{eve.currency}</div>
                                  <input
                                    style={{
                                      border: "none",
                                      flexGrow: 1,
                                    }}
                                    value={targetPayment.amount}
                                    onChange={(e) => { setTargetPayment({ ...targetPayment, amount: e.target.value }) }}
                                  />
                                  <Button
                                    variant="contained"
                                    sx={{
                                      borderLeft: '1px solid #fff',
                                      borderRadius: "0",
                                      backgroundColor: "#df0c62",
                                      height: "auto",
                                    }}
                                    onClick={() => {
                                      commitPayment(eve);
                                    }}
                                    disableElevation

                                  >
                                    Onay
                                  </Button>
                                  {targetPayment._id ? (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        borderLeft: '1px solid #fff',
                                        borderRadius: "0",
                                        backgroundColor: "#df0c62",
                                        height: "auto",
                                      }}
                                      onClick={() => { setTargetPayment(defPayment); }}
                                      disableElevation

                                    >
                                      Yeni
                                    </Button>
                                  ) : null}

                                </Stack>
                                {/* Purchase History */}
                                <Box
                                  sx={{
                                    borderBottom: "2px solid #ffd283",
                                    padding: "0 16px",
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    color="#df0c62"
                                    fontWeight="bold"
                                  >
                                    ÖDEME GEÇMİŞİ
                                  </Typography>
                                </Box>
                                {eve.payments?.length ? (
                                  <>
                                    {eve.payments.map((payment, pindex) => (
                                      <Box key={pindex}>
                                        <Stack
                                          display="flex"
                                          direction="row"
                                          justifyContent="space-between"
                                          sx={{
                                            cursor: 'pointer',
                                            borderBottom: "2px solid #ffd283",
                                            padding: "0 16px",
                                          }}
                                        >
                                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '90%' }}>
                                            <Typography sx={targetPayment._id == payment._id ? { textDecoration: 'line-through' } : {}} onClick={() => setTargetPayment(payment)} color="#df0c62" fontWeight="bold">
                                              {payment.amount} {eve.currency}
                                            </Typography>
                                            <Typography onClick={() => setTargetPayment(payment)} color="#df0c62" fontWeight="bold">
                                              {payment.created.split('T')[0].replaceAll('-', '.')}
                                            </Typography>
                                          </div>
                                          <Typography onClick={() => { if (window.confirm('Ödeme geçmişi silmek istediğinizde emin misiniz?')) removePayment(payment); }} color="#df0c62" fontWeight="bold">
                                            X
                                          </Typography>
                                        </Stack>
                                      </Box>
                                    ))}
                                  </>
                                ) : (
                                  <Typography color="#df0c62" fontWeight="bold">
                                    Boş
                                  </Typography>
                                )}



                                {/* End Of Left Side Stack Column*/}
                              </Stack>
                            </Box>
                            {/* Right Side */}
                            <Box flex={1}>
                              <Stack
                                display="flex"
                                direction="column"
                                sx={{
                                  border: "1px solid #df0c62",
                                  backgroundColor: "white",
                                  padding: "16px",
                                }}
                              >
                                <input style={{ border: 'none' }} color="#df0c62" value={targetPayment.comment}
                                  onChange={(e) => { setTargetPayment({ ...targetPayment, comment: e.target.value }) }}
                                  placeholder="ödeme ile ilgili bi not.." />

                                <Box display="flex" justifyContent="end">
                                  <Button
                                    variant="text"
                                    color="inherit"
                                    onClick={() => { setTargetPayment({ ...targetPayment, visible: !targetPayment.visible }) }}
                                    sx={!targetPayment.visible ? { color: "#df0c6250" } : { color: "#df0c62" }}
                                  >
                                    {!targetPayment.visible ? ('Gizli not') : ('Açık not')}
                                  </Button>
                                </Box>
                              </Stack>
                            </Box>
                            {/* End Of Accordion Stack */}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                      {/* Accordion End */}
                    </Box>
                  ))}
                </>
              ) : null}


            </Stack>
          </Box>
          {/* End Of Organizer Fee Information */}
        </Stack>
        {/*Set Rate By Event */}
        <Stack
          display="flex"
          direction="column"
          spacing={2}
          className={classes.scrollable}
          sx={{ padding: { xs: "16px 4px", sm: "16px" } }}
        >
          <Typography variant="h6" color="#df0c62" fontWeight="bold">
            ETKİNLİĞE GÖRE ORAN BELİRLE
          </Typography>
          <Stack
            display="flex"
            direction="column"
            sx={{ backgroundColor: "#ffd283" }}
            spacing={0}
          >
            {/* SEARCH BAR */}
            <Box sx={{ boxShadow: "0 0 2px 1px #fff0d7", display: 'none' }}>
              <SearchBar />
            </Box>
            {/* SEARCH BAR */}
            {events.map((ctm, indx) => (
              <Accordion
              key={indx}
                elevation={0}
                sx={{
                  backgroundColor: "transparent",
                }}
              >
                <AccordionSummary
                  onClick={() => { setEditingEvent(ctm); setEditingComission(ctm.commission || 0); }}
                  expandIcon={
                    <VisibilityOutlined sx={{ color: "#feb659" }} />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"

                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography fontWeight="bold" color="#df0c62">
                      {ctm.title}
                    </Typography>
                    <Typography fontWeight="bold" color="#df0c62" sx={{ marginRight: '15px' }}>
                      {ctm.commission ? (
                        <>{ctm.commission}%</>
                      ) : ('Tanımlanmadı')}

                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <CurrentEvent small={true} e_event={ctm} />
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
          <Stack display="flex" direction="row" spacing={2}>
            <Box
              sx={{
                backgroundColor: "#df0c62",
                padding: "8px",
              }}
              flex={1}
            >
              <Typography
                varaint="h6"
                fontWeight="bold"
                color="white"
                sx={{ fontSize: { xs: "0.6rem", sm: "1rem" } }}
              >
                {editingEvent?.title} Toplam Komisyon
              </Typography>
            </Box>
            <Stack
              display="flex"
              direction="row"
              sx={{ backgroundColor: "#fd0c62", border: "1px solid #fd0c62" }}
            >
              <Box flex={1}>
                <span style={{
                  border: "none",
                  height: "100%",
                  color: "#fd0c62",
                  fontWeight: "bolder",
                  outline: "none",
                  backgroundColor: 'white'
                }}>%</span>
                <input
                  style={{
                    border: "none",
                    height: "100%",
                    color: "#fd0c62",
                    fontWeight: "bolder",
                    outline: "none",
                  }}
                  value={editingComission}
                  onChange={(e) => { setEditingComission(e.target.value) }}
                />
              </Box>

              <Button
                disableElevation
                onClick={() => submitComission(editingEvent?._id, editingComission)}
                variant="contained"
                sx={{
                  backgroundColor: "#df0c62",
                  "&:hover": {
                    backgroundColor: "#df0c62",
                  },
                }}
              >
                Onay
              </Button>
            </Stack>
          </Stack>
          {/* End Of Set Rate By Event */}
        </Stack>
        {/* End Of Parent Stack */}
      </Stack>
    </React.Fragment>
  );
};

export default OrganizerDesktop;
