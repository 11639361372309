import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import TopBar from "../../AppBar/TopBar";
import aidKit from "../../../Assets/Images/aidKit.png";

import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../../Payment/CheckoutForm';
import GarantiForm from '../../Payment/GarantiForm';

import classes from "./styles.module.css";
import Footer from "../../Panel/Footer/Footer";
import { Close, Expand, ExpandMore } from "@mui/icons-material";
import { useEffect } from "react";
import AutoInput from "../../AutoComplete/AutoInput";
import { getAllCountries } from "../../../Data/general";
import { createPayment } from "../../../Data/payments";
import Autocomplete from "@mui/material/Autocomplete";
import { Link, useNavigate } from "react-router-dom";
import FooterTablet from "../../Panel/Footer/Tablet/FooterTablet";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import Loading from "../../Common/Loading";


const DesktopBuyTicket = ({ clientSecret,thisEvent, cart, removeItem, clearCart, total, order, setOrder, stripePromise, submitOrder, loading }) => {
  const navigate = useNavigate();
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const [isLogged, setIsLogged] = useState(localStorage.getItem('local'));
  const ticketsAmnt = [0, 1];
  const [address, setAddress] = useState('');
  const [checked, setChecked] = useState({ first: false, second: false });
  const [allowed, setAllowed] = useState(false);
  const isBigScreen = useMediaQuery({ query: '(min-width: 992px)' })

  const [continueAsGuest, setContinueAsGuest] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const { t } = useTranslation()

  const appearance = {
    theme: 'none',
    variables: { colorPrimary: '#df0b62', colorPrimaryText: '#df0b62' },
    rules: {
      '.Tab': { border: '2px solid #ffd689' },
      '.Tab:hover': { color: '#df0b62', boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)', },
      '.Tab--selected': { borderColor: '#df0b62', color: '#df0b62' },
      '.TabIcon--selected': { fill: '#df0b62' },
      '.Input': { color: '#df0b62', border: '2px solid #df0b62', borderRadius: 0, },
      '.Input::placeholder': { color: '#df0b62' },
      '.Input--invalid': { boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)', },
      '.Label': { color: '#df0b62' },
      '.p-LinkAutofillPromptButton': { color: '#df0b62' },
      '.RedirectText': { color: '#df0b62' }
    }
  };

  const [countries, setCountries] = useState([]);
  const [countrySelectorOpen, setCountrySelectorOpen] = useState(false);
  const [countdown, setCount] = useState("05:00");

  const fetchCountries = async () => {
    setCountries(await getAllCountries());
  };

  const getClocked = (num) => {
    if(num > 0){
      if(num > 9) return num;
      else return "0"+num;
    }else return "00";
  }

  const updateCount = async (extime,ttime) => {
    let timeleft = extime - ttime;

    let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

    if(!minutes && !seconds) return;
    else setCount(getClocked(minutes)+":"+getClocked(seconds));

  }

  const initTimer = async () => {
    let exTime = await localStorage.getItem('resExTime');
    if(!exTime) navigate('/');
    else{
      setInterval(() => { updateCount(new Number(exTime),new Number(new Date().getTime()));},1000);
    }
  }

  const isValidEmail = (val) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(val);
  }

  const isValidPhoneNumber = (val) => {
    const phoneNumberRegex = /^(\+\d{1,3}[- ]?)?(\d{10,15})$/;
    return phoneNumberRegex.test(val);
  }

  useEffect(() => {
    if (countrySelectorOpen) fetchCountries();
    else setCountries([]);
  }, [countrySelectorOpen]);

  useEffect(() => {
    if (order.firstName.length < 2 || order.lastName.length < 2 || !isValidEmail(order.email) || !isValidPhoneNumber(order.phoneNumber)) setDisabled(true)
    else setDisabled(false)
  }, [order])

  useEffect(() => {
    initTimer();
  },[]);

  // useEffect(() => {
  //   console.log(stripePromise,clientSecret);
  // },[stripePromise,clientSecret]);


  // console.log(event);
  return (
    <Box
      className={classes.container + " eventPageContainer"}
      sx={{ display: { "xs": "none", "sm": 'block' } }}
    >
      <TopBar color="#ffd283" />
      {/* Event Header */}
      <Box className={classes.eventHeader}>
        <Container maxWidth="md" sx={{ height: "100%" }}>
          <Stack display="flex" className={classes.eventStack} sx={{ height: "100%" }}>
            {/* Event Poster */}
            <Box flex={1}>
              <img
                src={CDN_URL + thisEvent?.image}
                alt="Event Poster"
                className={classes.eventPoster}
              />
            </Box>
            {/* Event Details */}
            <Box flex={2} className={classes.eventDetails}>
              <Stack display="flex" direction="column">
                <Typography
                  variant="h4"
                  color="#df0c62"
                  className={classes.titleStroke}
                >
                  {thisEvent?.title}
                </Typography>
                <Stack display="flex" direction="row">
                  <Box flex={1}>
                    <Typography variant="h6" fontWeight="bold" color="#df0c62">
                      {thisEvent?.location?.name}
                    </Typography>
                    <Typography paragraph color="#df0c62">
                      {thisEvent?.startDate}
                    </Typography>
                  </Box>
                </Stack>
                <Box className={classes.redDash}></Box>
                {/* Event Type */}
                <Typography paragraph color="#df0c62">
                  {thisEvent?.type}
                </Typography>
                <Typography paragraph color="#df0c62">
                    {t('Reservation Expires In:')} <strong>{countdown}</strong>
                  </Typography>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>

      {/* Middle Section */}
      <Container maxWidth="md" className={classes.middleSection}>
        {/* Parent Stack */}
        <Stack display="flex" direction="column" spacing={1}>
          {/* Amount Of Tickets */}
          <Stack display="flex" direction="row" alignItems="center">
            <Typography variant="h6" color="#df0c62" paragraph>
              {t('Tickets')}
            </Typography>
            <Box flex={1} sx={{ borderBottom: "2px solid #ffd283" }}></Box>
            <Typography variant="h6" color="#df0c62" paragraph>
              {t('There are tickets in your cart', { amount: cart?.length })}
              {/* Sepetinizde <strong style={{ fontWeight: "bold" }}>{cart?.length}</strong> adet
              bilet bulunmaktadır. */}
            </Typography>
          </Stack>

          <Stack display="flex" direction="row">
            <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
              <Typography
                variant="h6"
                fontWeight="bold"
                color="#df0c62"
                textAlign="center"
              >
                {t('Block')}
              </Typography>
            </Box>
            {!thisEvent.plan.isSimple ? (
              <>
                <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="#df0c62"
                    textAlign="center"
                  >
                    {t('Row')}
                  </Typography>
                </Box>
                <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="#df0c62"
                    textAlign="center"
                  >
                    {t('Seat')}
                  </Typography>
                </Box>
              </>
            ):null}
            <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
              <Typography
                variant="h6"
                fontWeight="bold"
                color="#df0c62"
                textAlign="center"
              >
                {t('Ticket Cost')}
              </Typography>
            </Box>
            <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
              <Typography
                variant="h6"
                fontWeight="bold"
                color="#df0c62"
                textAlign="center"
              >
                {t('Remove')}
              </Typography>
            </Box>
          </Stack>

          {/* Tickets In Cart */}
          {cart?.map((it, i) => (
            <Stack key={i} display="flex" direction="row">
              <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color="#df0c62"
                  textAlign="center"
                >
                  {"Main"}
                </Typography>
              </Box>
              {thisEvent && thisEvent.plan ? (
                <>
                {!thisEvent.plan.isSimple ? (
                  <>
                    <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="#df0c62"
                        textAlign="center"
                      >
                        {it.label.substring(0,1)}
                      </Typography>
                    </Box>
                    <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="#df0c62"
                        textAlign="center"
                      >
                        {thisEvent.plan.startsFromOne ? it.trueSeat : it.seat}
                      </Typography>
                    </Box>
                  </>
                ):(
                  <></>
                )}
                </>
              ):null}
              <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color="#df0c62"
                  textAlign="center"
                >
                  {it.price?.toFixed(2)} {thisEvent.currency?.symbol}
                </Typography>
              </Box>

              <Box
                flex={1}
                sx={{ borderBottom: "2px solid #ffd289" }}
                display="flex"
                justifyContent="center"
              >
                <IconButton size="medium" onClick={() => removeItem(i)}>
                  <Close sx={{ color: "#df0c62" }} />
                </IconButton>
              </Box>
            </Stack>
          ))}

          <Stack
            display="flex"
            direction="row"
            justifyContent="end"
            spacing={1}
          >
            <Button
              variant="contained"
              onClick={clearCart}
              sx={{
                backgroundColor: "#df0c62",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
            >
              {t('Remove All')}
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#df0c62",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
              onClick={() => navigate("/event/" + thisEvent.slug)}
            >
              {t('Add Seat')}
            </Button>
            {
              false
                ?
                <div>
                  <Button
                    className="me-2"
                    variant="contained"
                    sx={{
                      backgroundColor: "#df0c62",
                      "&:hover": { backgroundColor: "#df0c62" },
                    }}
                    onClick={() => setContinueAsGuest(true)}
                  >
                    {t('Continue as Guest')}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#df0c62",
                      "&:hover": { backgroundColor: "#df0c62" },
                    }}
                    onClick={() => navigate("/login")}
                  >
                    {t('Login')}
                  </Button>
                </div>
                :
                null
            }
          </Stack>
          <div>

          </div>
          {!isLogged ? (
                <Stack display="flex" direction="column" sx={{marginTop:'20px!important'}} spacing={2}>
                  <Stack display="flex" direction="row" spacing={1}>
                    <Box flex={1}>
                      <Typography color="#df0c62" fontWeight="bold">
                        {t('First Name')} *
                      </Typography>
                      <input
                        value={order.firstName}
                        onChange={(e) => setOrder({ ...order, firstName: e.target.value })}
                        style={{
                          width: "100%",
                          border: "2px solid #df0c63",
                          outline: "none",
                        }}
                        name="name"
                      />
                    </Box>
                    <Box flex={1}>
                      <Typography color="#df0c62" fontWeight="bold">
                        {t('Last Name')} *
                      </Typography>
                      <input
                        value={order.lastName}
                        onChange={(e) => setOrder({ ...order, lastName: e.target.value })}
                        style={{
                          width: "100%",
                          border: "2px solid #df0c63",
                          outline: "none",
                        }}
                        name="lastName"
                      />
                    </Box>
                  </Stack>
                  <Box flex={1}>
                    <Typography color="#df0c62" fontWeight="bold">
                      {t('E-mail')} *
                    </Typography>
                    <input
                      value={order.email}
                      onChange={(e) => setOrder({ ...order, email: e.target.value })}
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography color="#df0c62" fontWeight="bold">
                      {t('Phone Number')} *
                    </Typography>
                    <input
                      value={order.phoneNumber}
                      onChange={(e) => setOrder({ ...order, phoneNumber: e.target.value })}
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                      name="id"
                    />
                  </Box>

                  <Box display="flex" justifyContent="end" pb={1}></Box>
                  {/* End of Accordion Stack */}
                </Stack>

          ) : null}


          <Accordion >
            <AccordionSummary
              disabled={disabled && !isLogged}
              expandIcon={
                <ExpandMore sx={{ color: "#df0c62" }} fontSize="large" />
              }
              aria-controls="panel1a-content"
              id="panel1a-header2"
            >
              <Stack display="flex" direction="row" width="100%">
                <Typography variant="h6" color="#df0c62" fontWeight="bold">
                  {t('Payment')}
                </Typography>
                <Box
                  flex={1}
                  sx={{ borderBottom: "2px solid #ffd289" }}
                ></Box>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {/* Child Accordion Stack */}
              <Stack display="flex" direction="column" spacing={2}>

                <Stack display="flex" direction="column" spacing={2}>

                {/* Right Side Total amounts */}
                <Box
                  sx={{
                    width:'100%',
                    border: "2px solid #df0c62",
                    justifyContent: "center",
                    padding: "32px",
                    height: 'fit-content'
                  }}
                >
                  <Stack
                    display="flex"
                    direction="column"
                    sx={{ flexGrow: 1 }}
                    spacing={4}
                  >
                    {/* row 1 */}
                    <Box
                      sx={{
                        borderBottom: "2px solid #ffd589",
                      }}
                    >
                      <Stack
                        display="flex"
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography color="#df0c62" fontWeight="bold">
                          {t('Total')}:
                        </Typography>
                        <Typography fontWeight="bold" color="#df0c62">
                          {total} {thisEvent?.currency?.symbol}
                        </Typography>

                      </Stack>

                    </Box>
                    <Typography color="#df0c62">*{t('vat_included')}</Typography>

                    {thisEvent && thisEvent.location ? (
                      <>
                      {thisEvent.location.country === "Turkey" && clientSecret ? (
                        <>
                        <GarantiForm secret={clientSecret} submit={submitOrder} loading={loading} />
                        </>
                      ):(
                        <>
                        <Box flex={1}>
                          {clientSecret && stripePromise && (
                            <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                              <CheckoutForm secret={clientSecret} submitOrder={submitOrder} loading={loading} />
                            </Elements>
                          )}
                        </Box>
                        </>
                      )}
                      </>
                    ):null}
                    <Accordion >
                      <AccordionSummary onClick={(e) => setOrder({ ...order, invoice: !order.invoice })}
                        disabled={disabled && !isLogged}
                        expandIcon={<></>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Stack  display="flex" direction="row" alignItems="center">
                          <Typography color="#df0c62" variant="h9" fontWeight="bold">
                            {t('I want bill')}
                          </Typography>
                          <Checkbox checked={order.invoice} color="error" />
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Box>
                              <Typography color="#df0c62" fontWeight="bold">
                                {t('Country')} *
                              </Typography>
                              <input
                                value={order.country}
                                onChange={(e) => setOrder({ ...order, country: e.target.value })}
                                style={{
                                  width: "100%",
                                  border: "2px solid #df0c63",
                                  outline: "none",
                                }}
                                name="country"
                              />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box>
                              <Typography color="#df0c62" fontWeight="bold">
                                {t('City')} *
                              </Typography>
                              <input
                                value={order.city}
                                onChange={(e) => setOrder({ ...order, city: e.target.value })}
                                style={{
                                  width: "100%",
                                  border: "2px solid #df0c63",
                                  outline: "none",
                                }}
                                name="city"
                              />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box>
                              <Typography color="#df0c62" fontWeight="bold">
                                {t('Zip Code')} *
                              </Typography>
                              <input
                                value={order.zip}
                                onChange={(e) => setOrder({ ...order, zip: e.target.value })}
                                style={{
                                  width: "100%",
                                  border: "2px solid #df0c63",
                                  outline: "none",
                                }}
                                name="country"
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Box>
                              <Typography color="#df0c62" fontWeight="bold">
                                {t('Address')}
                              </Typography>
                              <input
                                value={order.address1}
                                onChange={(e) => setOrder({ ...order, address1: e.target.value })}
                                style={{
                                  width: "100%",
                                  border: "2px solid #df0c63",
                                  outline: "none",
                                }}
                                name="adres1"
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <Typography color="#df0c62" fontWeight="bold">
                                {t('Address continuation')}
                              </Typography>
                              <input
                                value={order.address2}
                                onChange={(e) => setOrder({ ...order, address2: e.target.value })}
                                style={{
                                  width: "100%",
                                  border: "2px solid #df0c63",
                                  outline: "none",
                                }}
                                name="adres2"
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                  </Stack>
                </Box>
                  {/* Left Side Payment Detail */}



                </Stack>


              </Stack>
            </AccordionDetails>
          </Accordion>

          {/* End Of Parent Stack */}
        </Stack>

        {/* End of Parent Container */}
      </Container>

      <Box>
        {
          isBigScreen
            ? <Footer />
            : <FooterTablet />
        }
      </Box>
    </Box>
  );
};

export default DesktopBuyTicket;
