import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import TopBar from "../../AppBar/TopBar";
import rockStar from "../../../Assets/Images/rockStar.png";
import warmCore from "../../../Assets/Images/warmCore.png";
import aidKit from "../../../Assets/Images/aidKit.png";
import dann from "../../../Assets/Images/dann.png";
import violin from "../../../Assets/Images/violin.png";
import headPhone from "../../../Assets/Images/headphoneSticker.png";
import smallTicket from "../../../Assets/Images/smallTicket.png";
import AutoInput from "../../AutoComplete/AutoInput";
import City from "../../../Assets/svg/city.svg";



import { listEvents, listActives, listFronted } from "../../../Data/event";

import classes from "./styles.module.css";
import Footer from "../../Panel/Footer/Footer";
import { Link, Navigate, useNavigate } from "react-router-dom";
import CarouselDesktop from "../../Carousel/Desktop/CarouselDesktop";
import { useMediaQuery } from 'react-responsive'
import FooterTablet from "../../Panel/Footer/Tablet/FooterTablet";
import { useTranslation } from "react-i18next";

const HomeDesktop = () => {
  const { t } = useTranslation()
  const evts = [aidKit, rockStar, warmCore, dann, violin];
  const navigate = useNavigate();

  const CDN_URL = process.env.REACT_APP_CDN_URL;

  const [detail, setDetail] = useState(null);
  const [focusedImage, setFocusedImage] = useState(0);
  const [events, setEvents] = useState([]);
  const [allevents, setAllEvents] = useState([]);
  const isBigScreen = useMediaQuery({ query: '(min-width: 992px)' })



  const fetchEvents = async () => {
    let list = await listActives();
    setDetail(list[0]);
    setEvents(list);

    setAllEvents(await listFronted());
  }

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <Box
      className={classes.container}
      sx={{ display: { xs: "none", sm: "block" } }}
    >

      <TopBar color="#ffd283" />
      <Box className={classes.featured}>
        <div id={classes.background}></div>
        <Stack
          display="flex"
          direction="column"
          justifyContent="center"
          className={classes.featuredBox}
          spacing={0}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            color="white"
            className={classes.tio}
          >
            {t('for_fun')}
          </Typography>
          <Typography
            variant="h2"
            className={`jt --debug ${classes.titleStroke}`}
          >
            <span className="jt__row">
              <span className="jt__text">{t('CONCERT')}</span>
            </span>
            <span className="jt__row jt__row--sibling" aria-hidden="true">
              <span className="jt__text">{t('CONCERT')}</span>
            </span>
            <span className="jt__row jt__row--sibling" aria-hidden="true">
              <span className="jt__text">{t('CONCERT')}</span>
            </span>
            <span className="jt__row jt__row--sibling" aria-hidden="true">
              <span className="jt__text">{t('CONCERT')}</span>
            </span>
          </Typography>
        </Stack>

        {/* Featured Posters */}
        <Box className={classes.featuredPosters}>
          {/* <img src={rockStar} alt="rockStar" />
          <img src={warmCore} alt="warmCore" />
          <img src={headPhone} alt="headphone" /> */}
          <CarouselDesktop events={allevents} slidesToShow={3} />
        </Box>
      </Box>
      <Container maxWidth="md" sx={{marginBottom:'3rem'}} >
        {/* Parent Stack */}
        <Stack display="flex" direction="column" spacing={4}>
          {/* Search Country */}
          <Stack display="flex" direction="column" spacing={1}>
            <Typography variant="h5" className={classes.searchTitle}>
              {t('find_in_your_country')}
            </Typography>
            <Box className={classes.searchCountry}>
              <AutoInput />
              <img className={classes.cityImage} src={City} />
            </Box>
          </Stack>
          {/* Event List */}
          <Box>
            <Stack
              className={classes.eventsList}
              display="flex"
              direction="row"
              sx={{height:'500px'}}
              spacing={8}
            >
              <Box flex={0.5}>
                <Stack display="flex" direction="column" spacing={6}>
                  {events.map((item, index) => (
                    <Box key={index} sx={{ "&:hover": { cursor: "pointer" } }}>
                      <img
                        className={`${classes.eventListPoster} ${index === focusedImage
                          ? classes.focused
                          : classes.unfocused
                          }`}
                        src={CDN_URL + item.image}
                        alt={item.title}
                        onClick={() => {
                          setDetail(item);
                          setFocusedImage((prevValue) => index);
                        }}
                      />
                    </Box>
                  ))}
                </Stack>
              </Box>
              {detail ? (
                <Box flex={2} position="sticky" top={0}>
                  <Stack display="flex" direction="column" spacing={1}>
                    {/* Event Name Date and details */}
                    <Stack display="flex" direction="column" padding="32px 16px">
                      <Link to={'event/' + (detail?.slug || events[0]?.slug)}>
                        <Typography
                          variant="h4"
                          color="#df0c62"
                          className={classes.focusedTitle}
                        >
                          {detail ? detail.title : events[0].title}
                        </Typography>
                      </Link>
                      <Typography variant="h6" color="#df0c62">

                      </Typography>
                      <Typography paragraph sx={{ whiteSpace: 'pre-wrap',marginTop:'30px',height: '145px',overflow: 'hidden'}} color="#df0c62">
                        {detail ? detail.description.substring(0,500)+".." : events[0].description.substring(0,500)+".."}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "#df0c62",
                            fontWeight: "bold",
                          }}
                          href="*"
                        >
                          {detail ? (detail.location?.name) : (events[0].location?.name)}
                        </Link>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "2px dashed #df0c62",
                      }}
                    ></Box>
                    {/* Event's Available Dates */}
                    <Stack
                      display="flex"
                      direction="column"
                      className={classes.datesList}
                    >
                      <React.Fragment>
                        <Stack
                          display="flex"
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            borderBottom: "1px solid #ffd283",
                            padding: "8px 0",
                          }}
                        >
                          <Box flex={1}>
                            <Typography variant="h6" color="#df0c62">
                              {detail ? detail.startDate.split('T')[0] + " " + detail.startDate.split('T')[1] : events[0].startDate.split('T')[0] + " " + events[0].startDate.split('T')[1]}
                            </Typography>
                          </Box>
                          <Box
                            flex={1}
                            sx={{ display: "flex", justifyContent: "end" }}
                          >
                            <Box
                              flex={1}
                              className={classes.buyContainer}
                              onClick={() => navigate('/event/' + (detail?.slug || events[0]?.slug))}
                            >

                              <Typography className={classes.buyText}>
                                {t('Buy')}
                              </Typography>

                              <img
                                src={smallTicket}
                                alt="smallTicket"
                                className={classes.smallTicket}
                              />

                            </Box>
                          </Box>
                        </Stack>
                      </React.Fragment>
                    </Stack>
                  </Stack>
                </Box>
              ) : null}

            </Stack>
          </Box>
          {/* End Of Parent Stack */}
        </Stack>
      </Container>
      {/* Footer */}
      <Box>
        {
          isBigScreen
            ? <Footer />
            : <FooterTablet />
        }
      </Box>
    </Box>
  );
};

export default HomeDesktop;
