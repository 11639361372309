import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Modal,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select
} from "@mui/material";
import { toast } from "react-toastify";
import React, { useEffect, useState, useRef } from "react";
import NavBar from "../NavBar/NavBar";
import style from "./organizer.module.css";
import seats from '../../../Assets/Images/seats.png'
import Image from "../../pdf/Image";import { useTranslation } from "react-i18next";
import { Add, ArrowDropDown, Delete, ExpandMore } from "@mui/icons-material";
import { currencies } from "../../../constants/currencies";
import { saveImage, listCurrencies } from "../../../Data/general";
import { RiHistoryFill } from "react-icons/ri";

import { makeid } from "../../../functions/makeId";
import { convertToSlug } from "../../../Services/helpers";
import { standartSeats, defaultType, standartMap } from "../../../Services/helpers";
import Seatchart from "../../Seatchart/Seatchart.js";

const SeatingSubmit = props => {

  const CDN_URL = process.env.REACT_APP_CDN_URL;

const { t } = useTranslation();
  const alpha = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

  let plan = props.plan;
  // console.log(plan);
  const setPlan = props.changeplan;
  const currency = props.currency;

  const seatchartRef = useRef(null);

  // const [plan, setPlan] = useState({map:standartMap,standartSeatTypes:[...standartSeats]});
  const [isSimple, setSimple] = useState(props?.plan?.isSimple);

  const [selectedType, setSelectedType] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  const [mousePos, setMousePos] = useState({});
  const [seatClass, setSeatClass] = useState()
  const [prevPlanOpen, setPrevPlanOpen] = useState(false);
  let ref = useRef()

  useEffect(() => {
    
    // const oldKeys = Object.keys(plan.map.seatTypes)
    // const oldValues = Object.values(plan.map.seatTypes)
    // // console.log(oldValues)
    // let selected;
    // oldValues.map(o => {
    //   if (o.types === selectedType) selected = { ...o, cssClass: seatClass }
    // })

    // let body;
    // for (let i = 0; i < oldKeys.length; i++) {
    //   body = { ...body, [oldKeys[i]]: oldValues[i]?.types === selected?.types ? { ...selected } : { ...oldValues[i] } }
    // }
    // setPlan({ ...plan, map: { ...plan.map, seatTypes: { ...body } } })

  }, [seatClass])

  const handleMouseMove = (event) => {
    setMousePos({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {




    let handler = (e) => {
      if (!ref.current?.contains(e.target)) {
        setTimeout(() => {
          setShowPopup(false)
        }, 200)
      }
    }
    document.addEventListener("mousedown", handler)
    return () => {
      document.removeEventListener("mousedown", handler)
    }
  },[])

  useEffect(() => {
    if (selectedType !== null) setShowPopup(true)
  }, [selectedType]);

  const changeImg = (e) => {
    //saveImage
      if(e.target.files[0].size > 5000000){
        toast('Yüklenen resim 5MB fazla olamaz');
        e.target.value = "";
        e.target.type = "file";
      }else{
        let list = Array.from(e.target.files);
        setPlan({ ...plan, planImage: list[0] });
      }
    }

  const changeSchemaImg = (e) => {
    //saveImage
      if(e.target.files[0].size > 5000000){
        toast('Yüklenen resim 5MB fazla olamaz');
        e.target.value = "";
        e.target.type = "file";
      }else{
        let list = Array.from(e.target.files);
        setPlan({ ...plan, planScheme: list[0] });
      }
    }

  const updateMapSeatType = (variant, type, value) => {

    // console.log(variant, type, value);

    let targetType = variant.types;
    let types = { ...plan.map.seatTypes };
    let mp = { ...plan.map }

    switch (type) {
      case 'label':
        types[targetType].label = value;
        types[targetType].name = value;
        mp = { ...plan.map, seatTypes: types };
        setPlan({ ...plan, map: mp });
        break;
      case 'price':
        types[targetType].price = value == '' ? '' : parseInt(value);
        mp = { ...plan.map, seatTypes: types };
        setPlan({ ...plan, map: mp });
        break;
      case 'amountMinus':
        types[targetType].amount = parseInt(variant.amount) - 1;
        mp = { ...plan.map, seatTypes: types };
        setPlan({ ...plan, map: mp });
        break;
      case 'amountPlus':
        types[targetType].amount = parseInt(variant.amount) + 1;
        mp = { ...plan.map, seatTypes: types };
        setPlan({ ...plan, map: mp });
        break;
      case 'amount':
        types[targetType].amount = value;
        mp = { ...plan.map, seatTypes: types };
        setPlan({ ...plan, map: mp });
        break;
      case 'pickerTriger':
        if(types[targetType].open) types[targetType].open = false;
        else types[targetType].open = true;
        mp = { ...plan.map, seatTypes: types };
        setPlan({ ...plan, map: mp });
      break;
      case 'pickerCommit':
        types[targetType].cssClass = value;
        mp = { ...plan.map, seatTypes: types };
        setPlan({ ...plan, map: mp });
      break;
      case 'delete':
        if (variant.types == 'default') toast.warning(t('default_ticket_type_delete_error'));
        else {
          let newTypes = {};
          Object.keys(types).map((it) => {
            if (it != variant.types) newTypes[it] = types[it];
          });
          mp = { ...plan.map, seatTypes: newTypes };
          setPlan({ ...plan, map: mp });
          break;
        }

    }
    // let obj = {...plan.map.seatTypes};
    // arr[variant.types].label = e.target.value;
    // arr[variant.types].name = e.target.value;
    // arr[variant.types].types = (index ? convertToSlug(e.target.value) : 'default');
    // let mp = {...plan.map, seatTypes: arr};
    // setPlan({...plan, map: mp});
  }

  const ticketTypeControl = () => {
    // if (!plan.isSimple) {
    //   let object = {};
    //   Object.values(plan.map.seatTypes).forEach((item, i) => {
    //     object[item.types] = {...item};
    //   });
    //   let newmap = {...plan.map, seatTypes: object};
    //   plan.map = newmap;
    // }
  }

  const getClass = (index, curr) => {
    let { seatTypes } = plan.map;
    // console.log(typeof seatTypes);
    // console.log(seatTypes);
    let tclass = null;
    Object.values(seatTypes)?.map((it, ind) => {
      if (it.types != 'default') {
        it.seatRows?.forEach((rw) => {
          if (rw === index) tclass = it.cssClass;
        }
        );
      }
    });
    if (!tclass) tclass = Object.values(plan.map.seatTypes)[0].cssClass;
    return tclass;
  }

  const getTitle = (index) => {
    let title = '';
    let possible = alpha.length;
    if (index < possible) {
      title = alpha[index];
    } else if (index == possible) {
      title += alpha[0] + alpha[0];
    } else if (index - 1 == possible) {
      title += alpha[1] + alpha[1];
    } else {
      let rest = index % possible;
      let total = (index - 1) / possible;
      for (let i = 0; i < total; i++) {
        title += alpha[rest];
      }
    }
    return title.toUpperCase();
  }

  const addtotype = (row, type) => {
    let map = { ...plan.map };
    let types = { ...map.seatTypes };
    let newTypes = {};
    Object.values(types).map((it, ind) => {
      let newlist = [];
      if (it.types != type) {
        it.seatRows.map((it) => { if (it != row) newlist.push(it); });
        newTypes[it.types] = { ...it, seatRows: [...newlist] };
      } else {
        let list = it.seatRows;
        it.seatRows.map((it) => { if (it != row) newlist.push(it); });
        if (it.seatRows.length > newlist.length) {
          newTypes[it.types] = { ...it, seatRows: [...newlist] };
        } else newTypes[it.types] = { ...it, seatRows: [...newlist, row] };
      }
    });
    let newMap = { ...map, seatTypes: newTypes };
    setPlan({ ...plan, map: newMap });
  }

  const seatRowPicker = (curr) => {
    let { rows, seatTypes } = plan.map;
    // console.log(seatTypes)
    let picker = [];
    for (let i = 0; i < rows; i++) {
      picker.push(<div onClick={() => addtotype(i, curr)} className={"d-inline-flex sc-seat " + (getClass(i, curr))}>{getTitle(i)}</div>);
    }
    return picker;
  }

  const addtorowspacer = (ind) => {
    let { rowSpacers } = plan.map;
    let list = [];
    if (rowSpacers.find((it) => it == ind + 1)) {
      list = [...rowSpacers.filter((it) => it != ind + 1)];
    } else {
      list = [...rowSpacers, ind + 1];
    }
    let newMap = { ...plan.map, rowSpacers: list };
    setPlan({ ...plan, map: newMap });
  }

  const spaceRowPicker = () => {
    let { rows, rowSpacers } = plan.map;
    let picker = [];
    for (let i = 0; i < rows; i++) {
      let spaced = rowSpacers.find((it) => it == i + 1);
      picker.push(<div onClick={() => addtorowspacer(i)} style={spaced ? { borderBottom: '5px solid #df0c62' } : {}} className={"d-inline-flex sc-seat no-class"}>{getTitle(i)}</div>);
    }
    return picker;
  }

  const addtocolumnspacer = (ind) => {
    let { columnSpacers } = plan.map;
    let list = [];
    if (columnSpacers.find((it) => it == ind + 1)) {
      list = [...columnSpacers.filter((it) => it != ind + 1)];
    } else {
      list = [...columnSpacers, ind + 1];
    }
    let newMap = { ...plan.map, columnSpacers: list };
    setPlan({ ...plan, map: newMap });
  }

  const spaceColumnPicker = () => {
    let { columns, columnSpacers } = plan.map;
    let picker = [];
    for (let i = 0; i < columns; i++) {
      let spaced = columnSpacers.find((it) => it == i + 1);
      picker.push(<div onClick={() => addtocolumnspacer(i)} style={spaced ? { borderRight: '5px solid #df0c62' } : {}} className={"d-inline-flex sc-seat no-class"}>{i + 1}</div>);
    }
    return picker;
  }


  let seatCloseOpen = (a) => {
    // console.log(a);
    // console.log('here');
    let chart = seatchartRef?.current;
    if (chart) {
      let store = chart.store;
      let clicked = a;
      let map;

      if (plan.map.selectedSeats.find((it) => {
        if(it.row == a.row && it.col == a.col) return true;
        else return false;
      })) {
        let seats = plan.map.selectedSeats.filter((it) => {
          if(it.row == a.row && it.col == a.col) return false;
          else return true;
        });
        map = { ...plan.map, selectedSeats: [...seats]};
      } else {
        map = { ...plan.map, selectedSeats: [...plan.map.selectedSeats, clicked] };
      }

      setPlan({ ...plan, map: map });
    }
  }

  const togglePlanType = (e) => {
    // let map = {...plan.map};
    // if(e.target.value == 'true') map.standartSeatTypes = [...standartSeats];
    // setPlan({ ...plan, standartSeatTypes: [...standartSeats], isSimple: (e.target.value == 'true' ? true : false) });

    setPlan({ ...plan, isSimple: (e.target.value == 'true' ? true : false) });
  }

  const togglePlanDirection = (e) => {
    setPlan({ ...plan, inverted: (e.target.value == 'true' ? true : false) });
  }

  const togglePlanCounting = (e) => {
    setPlan({ ...plan, startsFromOne: (e.target.value == 'true' ? true : false) });
  }

  const listenerInit = (chart) => {
    chart?.store?.addEventListener('seatchange', (a, b) => {
      seatCloseOpen(a.current.index);
    });
  }

  const changePlan = (ind) => {
    let list = [...props.prevPlans];
    let pickedPlan = list[ind];
    console.log(pickedPlan);
    list.splice(ind,1);
    let thisPlan = {...props.plan};
    list.unshift(thisPlan);
    setPlan(pickedPlan);
    props.setPrevPlans(list);
  }


  useEffect(() => {
    ticketTypeControl();

    setTimeout(() => {
      listenerInit(seatchartRef.current);
    }, 400);

  }, [plan]);

  const mstyle = {
    position: "absolute",
    width: 400,
    bgcolor: "#fff0d7",
    left: 0,
    right: 0,
    margin: "auto",
    top: 0,
    bottom: 0,
    height: 400,
    boxShadow: 24,
    p: 4,
  };

  const popup = () => {
    return (
      <div style={{ position: 'absolute', width: '80px', backgroundColor: 'white', top: mousePos.y, left: mousePos.x, padding: '10px', cursor: 'pointer' }}>
        <div className="color-picking mb-1 a-class" onClick={() => setSeatClass('a-class')}>
        </div>
        <div className="color-picking mb-1 b-class" onClick={() => setSeatClass('b-class')}>
        </div>
        <div className="color-picking mb-1 c-class" onClick={() => setSeatClass('c-class')}>
        </div>
        <div className="color-picking mb-1 d-class" onClick={() => setSeatClass('d-class')}>
        </div>
        <div className="color-picking mb-1 e-class" onClick={() => setSeatClass('e-class')}>
        </div>
        <div className="color-picking mb-1 f-class" onClick={() => setSeatClass('f-class')}>
        </div>
        <div className="color-picking mb-1 g-class" onClick={() => setSeatClass('g-class')}>
        </div>
        <div className="color-picking mb-1 h-class" onClick={() => setSeatClass('h-class')}>
        </div>
        <div className="color-picking mb-1 i-class" onClick={() => setSeatClass('i-class')}>
        </div>
        <div className="color-picking mb-1 j-class" onClick={() => setSeatClass('j-class')}>
        </div>
        <div className="color-picking mb-1 k-class" onClick={() => setSeatClass('k-class')}>
        </div>
        <div className="color-picking mb-1 no-class" onClick={() => setSeatClass('no-class')}>
        </div>
      </div>
    )
  }

  return (
    <div className="SeatingBox" style={{ height: "100vh", backgroundColor: "#fcf2e2" }}>
      <NavBar color="#ffd283" />
      <Container className="planBox" maxWidth="lg" sx={{ paddingTop: "2.5%", height: 'calc(100vh - 78px)', overflowY: 'auto' }}>
        <Stack display="flex" direction="column">
          <Typography
            variant="h6"
            textAlign="end"
            sx={{ color: "#df0c62", cursor: "pointer" }}
            onClick={props.onFieldClose}
          >
            Kapat
          </Typography>

          <Typography sx={{width:'fit-content', position:'relative'}} variant="h4" color="#df0c62">
            Oturma Düzeni
            {props.prevPlans && props.prevPlans.length ? <button style={{position: 'absolute',right: -50,top:0,bottom:0,margin:'auto',background:'transparent',color: '#df0b62',border:'none', width: 30, height: 30, fontSize: 25}} onClick={() => {setPrevPlanOpen(!prevPlanOpen)}}><RiHistoryFill /></button> :null}
          </Typography>
          
          <Modal open={!!prevPlanOpen} onClose={() => {setPrevPlanOpen(false)}}>
            <div style={{...mstyle}}>
            {props.prevPlans && props.prevPlans.length ? (
              <div style={{ backgroundColor: "#fff0d7", overflow: 'auto', padding: 20, height: '100%', width: '100%', display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            Mekan Planları <br />
              {props.prevPlans.map((pp,pind) => (
                <button style={{cursor: "pointer",
                  width: "100%",
                  margin: "10px 0",
                  border: "none",
                  background: "#df0b62",
                  color: "white",
                  fontSize: "18px",
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: 600}}  onClick={() => {changePlan(pind)}}>{pind+ 1} <span>{pp.map.rows} X {pp.map.columns} </span> {pp.created ? pp.created.split('T')[0] : "Örnek"}</button>
              ))}
              </div>
            ):null}
            </div>
          </Modal>
          {plan ? (
            <div className="row">
              <div className="col-4 flex-column" style={{ paddingBottom: '40px' }}>
                <FormControl>
                  <RadioGroup
                    sx={{
                      margin: '10px 0',
                      color: "#df0c62",
                      '&.Mui-checked': {
                        color: "#df0c62",
                      },
                    }}
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={plan.isSimple ? "true" : "false"}
                    onChange={(e) => { togglePlanType(e); }}
                  >
                    <FormControlLabel value="true" control={<Radio sx={{
                      color: "#df0c62",
                      '&.Mui-checked': {
                        color: "#df0c62",
                      },
                    }} />} label="Basit" />
                    <FormControlLabel value="false" control={<Radio sx={{
                      color: "#df0c62",
                      '&.Mui-checked': {
                        color: "#df0c62",
                      },
                    }} />} label="Planlı" />
                  </RadioGroup>
                </FormControl>
                <div className="d-flex flex-row">
                  <div className="d-flex flex-column align-items-center justify-content-center p-2">
                    <Box
                      className=" d-flex flex-col align-items-center justify-content-between planImageBox"
                      sx={{ "&:hover": { cursor: "pointer" }, color: "#df0c62", flexDirection:'column', border:'1px solid #df0c62' }}
                    >
                          {typeof plan.planImage == 'object' ? (
                            <img src={URL.createObjectURL(plan.planImage)} height="70px" width="70px" onClick={(e) => {e.target.nextSibling.click()}} />
                          ): !plan.planImage || plan.planImage == '' ? (
                            <img src={'https://placehold.co/70x70?text=Resim'} height="70px" width="70px" onClick={(e) => {e.target.nextSibling.click()}}/>
                          ):(
                            <img src={CDN_URL+plan.planImage} height="70px" width="70px" onClick={(e) => {e.target.nextSibling.click()}}/>
                          )}
                          <input
                            className="mb-4"
                            type="file"
                            id="planImage"
                            style={{width:0,height:0,overflow:'hidden',padding:0,position:'absolute'}}
                            value={plan.planImage ? plan.planImage.filename : null}
                            name="image"
                            onChange={(e) => {changeImg(e)}}
                          />

                    </Box>
                    <Typography sx={{fontSize: '13px'}} color="#df0c62">
                      Resim
                    </Typography>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center p-2">
                      <Box
                        className=" d-flex flex-col align-items-center justify-content-between planImageBox"
                        sx={{ "&:hover": { cursor: "pointer" }, color: "#df0c62", flexDirection:'column', border:'1px solid #df0c62' }}
                      >
                            {typeof plan.planScheme == 'object' ? (
                              <img src={URL.createObjectURL(plan.planScheme)} height="70px" width="70px" onClick={(e) => {e.target.nextSibling.click()}} />
                            ): !plan.planScheme || plan.planScheme == '' ? (
                              <img src={'https://placehold.co/70x70?text=Resim'} height="70px" width="70px" onClick={(e) => {e.target.nextSibling.click()}}/>
                            ):(
                              <img src={CDN_URL+plan.planScheme} height="70px" width="70px" onClick={(e) => {e.target.nextSibling.click()}}/>
                            )}
                            <input
                              className="mb-4"
                              type="file"
                              id="planSchemeImage"
                              style={{width:0,height:0,overflow:'hidden',padding:0,position:'absolute'}}
                              value={plan.planScheme ? plan.planScheme.filename : null}
                              name="schemaImage"
                              onChange={(e) => {changeSchemaImg(e)}}
                            />

                      </Box>
                      <Typography sx={{fontSize: '13px'}} color="#df0c62">
                        Şema
                      </Typography>
                      </div>

                </div>
                <Typography variant="h5" fontWeight="bold" color="#df0c62">
                  {plan.isSimple ? 'Bilet Türleri' : 'Plan'}
                </Typography>

                {plan.isSimple ? (
                  <>
                    {plan.standartSeatTypes?.map((variant, index) => (
                      <>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <input type='text' style={{ width: '60%', margin: '5px 0 0 0', color: '#df0b62', border: '1px solid #ffd283' }}
                          value={variant.label}
                          placeholder="Tür"
                          onChange={(e) => {
                            let arr = [];
                            plan.standartSeatTypes.forEach((item, i) => {
                              if(i === index) arr.push({...item, label: e.target.value, name: e.target.value, types: convertToSlug(e.target.value)});
                              else arr.push({...item});
                            });
                            setPlan({ ...plan, standartSeatTypes: arr });
                          }}
                        />
                        <Typography
                          sx={{ width: '7%', textAlign:'center', display: 'inline-block', overflow: 'hidden', color: "#df0c62", cursor: "pointer", fontSize: '16px' }}
                        >
                          {currency}
                        </Typography>
                        <input type='text' style={{ width: '35%', margin: '5px 0 0 0', color: '#df0b62', border: '1px solid #ffd283' }}
                          value={variant.price}
                          placeholder="Fiyat"
                          onChange={(e) => {
                            let arr = [];
                            plan.standartSeatTypes.forEach((item, i) => {
                              arr.push({...item, price: (i === index ? e.target.value : item.price)});
                            });
                            setPlan({ ...plan, standartSeatTypes: [...arr] });
                          }}
                        />
                        </div>

                        <div style={{ margin: '10px 0', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

                          <div className="input-group" style={{ width: '100%', alignItems: 'center' }}>
                            <div className="input-group-prepend">
                              <button onClick={(e) => { let arr = [];
                              plan.standartSeatTypes.forEach((item, i) => {
                                if(i === index) arr.push({...item, amount: (item.amount - 1)});
                                else arr.push({...item});
                              });
                               setPlan({ ...plan, standartSeatTypes: arr }); }}
                                className="quantityBut btn btn-outline-secondary" type="button">-</button>
                            </div>
                            <input
                              placeholder="Miktar"
                              value={variant.amount}
                              onChange={(e) => {
                                let mp = [...plan.standartSeatTypes];
                                let val = e.target.value.replace(/\D/g, '');
                                if(val != ''){
                                  if(parseInt(val) < 0) mp[index].amount = 0;
                                  else mp[index].amount = parseInt(val);
                                }else mp[index].amount = val;
                                setPlan({ ...plan, standartSeatTypes: mp });
                              }}
                              style={{ width: '100px', margin: '0 10px', color: '#df0b62', border: '1px solid #ffd283' }}
                            />
                            <div className="input-group-append">
                              <button onClick={(e) => {
                                let arr = [];
                                plan.standartSeatTypes.forEach((item, i) => {
                                  if(i === index) arr.push({...item, amount: (item.amount + 1)});
                                  else arr.push({...item});
                                });
                                setPlan({ ...plan, standartSeatTypes: arr }); }}
                                className="quantityBut btn btn-outline-secondary" type="button">+</button>
                            </div>
                          </div>
                          <button onClick={(e) => { let arr = [...plan.standartSeatTypes]; arr.splice(index, 1); setPlan({ ...plan, standartSeatTypes: arr }); }}
                            className="quantityBut btn btn-outline-secondary" type="button"><Badge color="error">
                              <Delete sx={{ color: "#df0c62" }} />
                            </Badge></button>


                        </div>
                      </>
                    ))}

                    <button onClick={(e) => { let arr = [...plan.standartSeatTypes]; arr.push(standartSeats[0]); setPlan({ ...plan, standartSeatTypes: arr }); }}
                      className="quantityBut btn btn-outline-secondary" type="button">Tür Ekle</button>
                  </>
                ) : (
                  <>
                    <div>
                      <p style={{ color: '#df0c62', margin: '10px 0 0 0' }}>Sıra sayısı:</p>
                      <div style={{ margin: '10px 0', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

                        <div className="input-group" style={{ width: '100%', alignItems: 'center' }}>
                          <div className="input-group-prepend">
                            <button onClick={(e) => { let mp = { ...plan.map }; mp.rows--; setPlan({ ...plan, map: mp }); console.log(seatchartRef); }}
                              className="quantityBut btn btn-outline-secondary" type="button">-</button>
                          </div>
                          <input
                            placeholder="Miktar"
                            value={plan.map.rows}
                            onChange={(e) => {
                              let mp = { ...plan.map };
                              let val = e.target.value.replace(/\D/g, '');
                              if(val != ''){
                                if(parseInt(val) < 0) mp.rows = 0;
                                else mp.rows = parseInt(val);
                              }else mp.rows = val;
                              setPlan({ ...plan, map: mp });
                            }}
                            style={{ width: '100px', margin: '0 10px', color: '#df0b62', border: '1px solid #ffd283' }}
                          />
                          <div className="input-group-append">
                            <button onClick={(e) => { let mp = { ...plan.map }; mp.rows++; setPlan({ ...plan, map: mp }); }}
                              className="quantityBut btn btn-outline-secondary" type="button">+</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p style={{ color: '#df0c62', margin: '10px 0 0 0' }}>Koltuk sayısı:</p>
                      <div style={{ margin: '10px 0', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

                        <div className="input-group" style={{ width: '100%', alignItems: 'center' }}>
                          <div className="input-group-prepend">
                            <button onClick={(e) => {
                                let mp = { ...plan.map };
                                mp.columns--;
                                setPlan({ ...plan, map: mp });
                               }}
                              className="quantityBut btn btn-outline-secondary" type="button">-</button>
                          </div>
                          <input
                            placeholder="Miktar"
                            value={plan.map.columns}
                            onChange={(e) => {
                              let mp = { ...plan.map };
                              let val = e.target.value.replace(/\D/g, '');
                              if(val != ''){
                                if(parseInt(val) < 0) mp.columns = 0;
                                else mp.columns = parseInt(val);
                              }else mp.columns = val;
                              setPlan({ ...plan, map: mp });
                             }}
                            style={{ width: '100px', margin: '0 10px', color: '#df0b62', border: '1px solid #ffd283' }}
                          />
                          <div className="input-group-append">
                            <button onClick={(e) => { let mp = { ...plan.map };
                            mp.columns++;
                            setPlan({ ...plan, map: mp }); }}
                              className="quantityBut btn btn-outline-secondary" type="button">+</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-column justify-content-center align-items-center'>

                    <FormControl>
                      <RadioGroup
                        sx={{
                          margin: '10px 0',
                          color: "#df0c62",
                          '&.Mui-checked': {
                            color: "#df0c62",
                          },
                        }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={plan.inverted ? "true" : "false"}
                        onChange={(e) => { togglePlanDirection(e); }}
                      >
                        <FormControlLabel value="false" control={<Radio sx={{
                          color: "#df0c62",
                          '&.Mui-checked': {
                            color: "#df0c62",
                          },
                        }} />} label="Sıralama Soldan Sağa" />
                        <FormControlLabel value="true" control={<Radio sx={{
                          color: "#df0c62",
                          '&.Mui-checked': {
                            color: "#df0c62",
                          },
                        }} />} label="Sıralama Sağdan Sola" />
                      </RadioGroup>
                    </FormControl>

                    <FormControl>
                      <RadioGroup
                        sx={{
                          margin: '0 0 10px 0',
                          color: "#df0c62",
                          '&.Mui-checked': {
                            color: "#df0c62",
                          },
                        }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={plan.startsFromOne ? "true" : "false"}
                        onChange={(e) => { togglePlanCounting(e); }}
                      >
                        <FormControlLabel value="false" control={<Radio sx={{
                          color: "#df0c62",
                          '&.Mui-checked': {
                            color: "#df0c62",
                          },
                        }} />} label="Koltuk Numarasından Başla" />
                        <FormControlLabel value="true" control={<Radio sx={{
                          color: "#df0c62",
                          '&.Mui-checked': {
                            color: "#df0c62",
                          },
                        }} />} label="Numarasını Birden Başla" />
                      </RadioGroup>
                    </FormControl>

                    </div>

                    <Typography
                      variant="h6"
                      sx={{ color: "#df0c62" }}
                    >
                      Bilet Türleri
                    </Typography>
                    <div ref={ref}>

                    {Object.values(plan.map.seatTypes).map((variant, index) => (
                      <div style={{ borderBottom: '1px solid #df0c62', margin: '15px 0' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>



                          <div onClick={() => updateMapSeatType(variant,'pickerTriger',null)} className={`d-inline-flex position-relative ${variant.types} sc-seat ` + variant.cssClass}>
                            <ul className={'colorPicker '+(variant.open ? 'open' : '')}>
                              <div className={'colorPickerItem '+(variant.cssClass == 'a-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','a-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat a-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'b-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','b-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat b-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'c-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','c-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat c-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'd-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','d-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat d-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'e-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','e-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat e-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'f-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','f-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat f-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'g-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','g-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat g-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'h-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','h-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat h-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'i-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','i-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat i-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'j-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','j-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat j-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'k-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','k-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat k-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'l-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','l-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat l-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'm-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','m-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat m-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'n-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','n-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat n-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'o-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','o-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat o-class`}></div></div>
                              <div className={'colorPickerItem '+(variant.cssClass == 'p-class' ? 'picked' :'')} onClick={() => {updateMapSeatType(variant,'pickerCommit','p-class')}} ><div className={`d-inline-flex ${variant.types} sc-seat p-class`}></div></div>
                            </ul>
                          </div>

                          <input type='text' style={{ width: '40%', margin: '5px 0 0 0', color: '#df0b62', border: '1px solid #ffd283' }}
                            value={variant.label}
                            placeholder="Tür"
                            onChange={(e) => {
                              updateMapSeatType(variant, 'label', e.target.value);
                            }}
                          />
                          <Typography
                            sx={{ textAlign: 'center', width: '5%', margin: '0 1%', display: 'inline-block', overflow: 'hidden', color: "#df0c62", cursor: "pointer", fontSize: '16px' }}
                          >
                            {currency}
                          </Typography>
                          <input type='text' style={{ width: '20%', margin: '5px 0 0 0', color: '#df0b62', border: '1px solid #ffd283' }}
                            value={variant.price}
                            placeholder="Fiyat"
                            onChange={(e) => {
                              updateMapSeatType(variant, 'price', e.target.value)
                            }}
                          />

                          <button style={{ width: '15%', display: 'inline-block', marginLeft: '7%' }} onClick={(e) => { updateMapSeatType(variant, 'delete', null) }}
                            className="quantityBut btn btn-outline-secondary centeredFlex" type="button"><Badge color="error">
                              <Delete sx={{ color: "#df0c62" }} />
                            </Badge></button>
                        </div>

                        <div style={{ margin: '10px 0', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

                          {variant.types != 'default' ? (
                            <div>
                              {seatRowPicker(variant.types)}
                            </div>
                          ) : null}

                          </div>
                        </div>
                      ))}
                    </div>
                    <button onClick={(e) => {
                      let obj = { ...plan.map.seatTypes };
                      let order = Object.keys(obj).length;
                      obj[defaultType.types + (order + 1)] = { ...defaultType, cssClass: alpha[Object.values(plan.map.seatTypes).length] + '-class', types: defaultType.types + (order + 1) };
                      let mp = { ...plan.map, seatTypes: obj };
                      setPlan({ ...plan, map: mp });
                    }}
                      className="quantityBut btn btn-outline-secondary mb-3" type="button">Tür Ekle</button>

                    <Typography
                      variant="h6"
                      sx={{ color: "#df0c62" }}
                    >
                      Sıra Bolşukları
                    </Typography>

                    <div className="mb-3">
                      {spaceRowPicker()}
                    </div>

                    <Typography
                      variant="h6"
                      sx={{ color: "#df0c62" }}
                    >
                      Koltuk Bolşukları
                    </Typography>

                    <div className="mb-5">
                      {spaceColumnPicker()}
                    </div>

                    <Typography
                      variant="h6"
                      sx={{ color: "#df0c62" }}
                    >
                      Kapalı Koltuklar
                    </Typography>

                    <Typography
                      variant="h8"
                      sx={{ color: "#df0c62" }}
                    >
                      Önizlemede seçebilebilirler.
                    </Typography>



                  </>
                )}

              </div>
              <div className="col-8" style={{ opacity: 0.6 }}>
                <div style={{ color: "#df0b62" }}>Önizleme</div>
                {!plan.isSimple ? (
                  <>
                    {plan.map ? (<Seatchart ref={seatchartRef} options={plan} />) : null}
                  </>
                ) : (
                  <div style={{ margin: '50px 0', color: '#df0b62' }}>
                    {plan.standartSeatTypes.map((variant, index) => (
                      <div style={{ margin: '10px 0', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

                        <p style={{ width: '100px', marginBottom: 0 }}>{variant.label}</p>
                        <p style={{ width: '100px', marginBottom: 0 }}>{variant.price}</p>


                        <div className="input-group" style={{ width: '200px' }}>
                          <div className="input-group-prepend">
                            <button
                              className="quantityBut btn btn-outline-secondary" type="button">-</button>
                          </div>
                          <input
                            value={0}
                            style={{ width: '40px', margin: '0 10px', color: '#df0b62', border: '1px solid #ffd283' }}
                          />
                          <div className="input-group-append">
                            <button
                              className="quantityBut btn btn-outline-secondary" type="button">+</button>
                          </div>
                        </div>

                        <p style={{ marginBottom: 0 }}>Kalan Miktar: {variant.amount - variant.sold}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>

            </div>
          ) : null}

        </Stack>
      </Container>
    </div>
  );
};

export default SeatingSubmit;
