import { PaymentElement } from '@stripe/react-stripe-js';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  Checkbox,
} from "@mui/material";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

export default function CheckoutForm({ submitOrder, secret, hotSale }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState({ first: false, second: false, third: false });
  const [allowed, setAllowed] = useState(false);
  const { t } = useTranslation()

  const returnUrl = !hotSale ? "/congratulations?orderSucc=1&oid=" : "/seller?orderSucc=1&oid=";


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;


    await submitOrder(secret).then(async (r) => {

      if (r?._id) {

        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: window.location.origin + returnUrl + r._id,
          },
        });


        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else setMessage("An unexpected error occured.");

        setIsLoading(false);
      } else setIsLoading(false);
    });
  }

  useEffect(() => {
    if (Object.values(checked).find((it) => it == false) != undefined) {
      setAllowed(false);
    } else setAllowed(true);
  }, [checked]);

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element"/>

      <div style={{ margin: '20px 0' }}>
        <Typography variant="h6" fontWeight="bold" color="#df0c62">
          {t('Legal Notices')}:
        </Typography>

        {/* First Box */}
        <Box sx={{ borderBottom: "2px solid #ffd589", }}>
          <Stack display="flex" direction="row" alignItems="center">
            <Checkbox
              value={checked.first}
              onChange={(e) => { setChecked({ ...checked, first: e.target.checked }) }} style={{ color: "#df0c62" }} />
            <Link to="/page/gizlilik-politikasi" target="_blank" style={{ textDecoration: "none" }}>
              <Typography
                variant="h7"
                color="#df0c62"
                sx={{ "&:hover": { fontWeight: "bold" } }}
              >
                {t('Privacy Policy')}
              </Typography>
            </Link>
          </Stack>
        </Box>
        {/* Second Box */}
        <Box sx={{ borderBottom: "2px solid #ffd589" }}>
          <Stack display="flex" direction="row" alignItems="center">
            <Checkbox
              value={checked.second}
              onChange={(e) => { setChecked({ ...checked, second: e.target.checked }) }} style={{ color: "#df0c62" }} />
            <Link to="/page/mesafeli-satis-sözlesmesi" target="_blank" style={{ textDecoration: "none" }}>
              <Typography
                variant="h7"
                color="#df0c62"
                sx={{ "&:hover": { fontWeight: "bold" } }}
              >
                {t('Distance Selling Contract')}
              </Typography>
            </Link>
          </Stack>
        </Box>
        {/* Third Box */}
        <Box sx={{ borderBottom: "2px solid #ffd589", }}>
          <Stack display="flex" direction="row" alignItems="center">
            <Checkbox
              value={checked.third}
              onChange={(e) => { setChecked({ ...checked, third: e.target.checked }) }} style={{ color: "#df0c62" }} />
            <Link to="/page/iptal-ve-iade-politikasi" target="_blank" style={{ textDecoration: "none" }}>
              <Typography
                variant="h7"
                color="#df0c62"
                sx={{ "&:hover": { fontWeight: "bold" } }}
              >
                {t('Return Policy')}
              </Typography>
            </Link>
          </Stack>
        </Box>
      </div>
      {/* End Of Child Accordion Stack */}
      <Box display="flex" justifyContent="center">
        <Button
          id="submit"
          type="submit"
          variant="contained"
          onClick={(e) => {setIsLoading(true); handleSubmit(e);}}
          sx={{
            backgroundColor: "#df0c62",
            "&:hover": { backgroundColor: "#df0c62" },
          }}
          disabled={isLoading || !stripe || !elements || !allowed}
        >
          {isLoading ? <div className="spinner" id="spinner"></div> : "Ödemeyi Tamamla"}
        </Button>
      </Box>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}
