
export const getOptioned = (option) => {
  return {value: option._id, label: option.title};
}

export const getOptionedReso = (option) => {
  console.log(option);
  return {value: option._id, label: option.width + " x " + option.height};
}

export const getImageUrl = (img) => {
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  return CDN_URL + img;
}

export const generatePassword = (len = 8) => {
    var charset = "abcdefghijklmnopqrstuvwxyz0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < len; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

export const standartSeats = [
  {
    name: 'Default',
    types: 'default',
    label: 'Default',
    price: 20,
    amount: 100,
    sold: 0
  }
];

export const defaultType = {
    name: 'Default',
    types: 'default',
    label: 'Economy',
    cssClass: 'a-class',
    price: 10,
    seatRows:[]
};

export const standartMap = {
  rows: 7,
  columns: 10,
  seatTypes: {
    default:{
      name: 'Default',
      types: 'default',
      label: 'Economy',
      cssClass: 'a-class',
      price: 10,
      seatRows:[]
    }
  },
  rowSpacers: [3, 5],
  columnSpacers: [5],
  disabledSeats: [],
  selectedSeats: []
};


export const getSmallImage = (img) => {
  return img?.replace('image/','sm/image/');
}

export const convertToSlug = (Text) => {
  return Text.toLowerCase()
             .replace(/ /g, '-')
             .replace(/[^\w-]+/g, '');
}

export const getMediumImage = (img) => {
  return img?.replace('image/','md/image/');
}

export const getLargeImage = (img) => {
  return img?.replace('image/','lg/image/');
}
