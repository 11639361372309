import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom'
import MobileAppBar from '../../components/AppBar/Mobile/TopBar/MobileAppBar';
import TopBar from '../../components/AppBar/TopBar';
import Footer from '../../components/Panel/Footer/Footer';
import FooterMobile from '../../components/Panel/Footer/Mobile/FooterMobile';
import FooterTablet from '../../components/Panel/Footer/Tablet/FooterTablet';
import * as Info from './infos';
import NavBar from "../../components/AppBar/Mobile/NavBar/MobileNavBar";

const InfoPage = () => {

    const { info } = useParams()
    const [data, setData]= useState("<div></div>")
    const isMobile = useMediaQuery({ query: '(max-width: 575px)' })
    const isTablet = useMediaQuery({ query: '(min-width: 576px)' })
    const isBigScreen = useMediaQuery({ query: '(min-width: 992px)' })


    useEffect(() => {
        switch (info) {
            case 'hakkimizda':
                setData(Info['aboutUs'+(localStorage.getItem('e-ticket-al language'))]);
                break;
            case 'on-bilgilendirme-formu':
                setData(Info['preliminaryInformationForm'+(localStorage.getItem('e-ticket-al language'))]);
                break;
            case 'mesafeli-satis-sözlesmesi':
                setData(Info['distanceSalesAgreement'+(localStorage.getItem('e-ticket-al language'))]);
                break;
            case 'kullanici-sozlesmesi':
                setData(Info['userAgreement'+(localStorage.getItem('e-ticket-al language'))])
                break;
            case 'cerez-politikasi':
                setData(Info['cookiePolicy'+(localStorage.getItem('e-ticket-al language'))])
                break;
            case 'kvkk-aydinlatma-metni':
                setData(Info['kvkk'+(localStorage.getItem('e-ticket-al language'))])
                break;
            case 'kvkk-basvuru-formu':
                setData(Info['kvkkForm'+(localStorage.getItem('e-ticket-al language'))])
                break;
            case 'kvkk-riza-formu':
                setData(Info['kvkkApproval'+(localStorage.getItem('e-ticket-al language'))])
                break;
            case 'iptal-ve-iade-politikasi':
                setData(Info['cancellationAndRefund'+(localStorage.getItem('e-ticket-al language'))])
                break;
            case 'gizlilik-politikasi':
                setData(Info['privacy'+(localStorage.getItem('e-ticket-al language'))])
                break;
        }
        window.scroll(0, 0)
    }, [info])




    return (
        <div style={{marginTop:'50px'}}>

            <div style={{ minHeight: '60vh' }} >
                {
                    isMobile
                        ? <MobileAppBar color="#ffd283" />
                        : <TopBar color="#ffd283" />
                }
                <div
                    dangerouslySetInnerHTML={{ __html: data }}
                ></div>

            </div>
            <div className='bottom-0'>
                {
                    isMobile
                        ? <FooterMobile />
                        : isBigScreen
                            ? <Footer />
                            : <FooterTablet />
                }
            </div>
            {
                isMobile &&
                <NavBar />
            }
        </div>

    )
}

export default InfoPage
