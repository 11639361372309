import axios from "axios";
import cookies from 'react-cookies';
import conf from './config';

export default axios.create({
    // baseURL: conf.app.backUrl,
    baseURL: "https://eticketal.com/api",
    // baseURL: "http://localhost:8000/api",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": 'multipart/form-data'
    }
});
