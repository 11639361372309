import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { createPayment } from "../Data/payments";
import {makeReservation,clearReservation} from "../Data/reservation";
import DesktopBuyTicket from "../components/BuyTicket/Desktop/DesktopBuyTicket";
import MobileBuyTicket from "../components/BuyTicket/Mobile/MobileBuyTicket";
import {toast} from 'react-toastify';
import Loading from "../components/Common/Loading";

import TabletBuyTicket from "../components/BuyTicket/Tablet/TabletBuyTicket";
import { getById } from "../Data/event";
import { make, calculate } from "../Data/order";
const BuyTicket = ({ stripePromise }) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [clientSecret, setClientSecret] = useState('');
  const [thisEvent, setEvent] = useState(null);
  const [cart, setCart] = useState(null);
  const [total, setTotal] = useState(0);
  const [serviceTotal, setServiceTotal] = useState(0);
  const [order, setOrder] = useState({
    invoice: false,
    country: "",
    city: "",
    zip: "",
    address1: "",
    address2: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    cardName: "",
    cardNumber: "",
    cardDate: "",
    cardCVC: ""
  });


  useEffect(() => {
    window.dispatchEvent(new Event('storage'))
  }, [cart])


  const generatePassword = (len = 6) => {
      var charset = "abcdefghijklmnopqrstuvwxyz0123456789",
          retVal = "";
      for (var i = 0, n = charset.length; i < len; ++i) {
          retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
  }

  const fetching = async () => {
    let _cart = await localStorage.getItem('cart');
    if (_cart) {
      let cart = JSON.parse(_cart);
      if (cart?.length) {
        let id = cart[0].event;
        let _event = await getById(id);
        let newList = [];
        let _total = await calculate(cart);
        if(_event.location?.country !== 'Turkey' && _total) setClientSecret(await createPayment(_total,_event.currency.code.toLowerCase()));
        else setClientSecret(generatePassword(10));
        if (!_event.plan.isSimple) {
          cart.map((it, ind) => {
            let price = Object.values(_event.plan.map.seatTypes).find((type) => type.type == it.type)?.price;
            newList.push({ ...it, price: price });
          });
        } else {
          cart.map((it, ind) => {
            let price = Object.values(_event.plan.standartSeatTypes).find((type) => type.types == it.type)?.price;
            newList.push({ ...it, price: price });
          });
        }

        setTotal(_total);
        setEvent(_event);
        setCart(newList);

      } else navigate('/');
    } else navigate('/');
  }

  const clearCart = async () => {
    await clearReservation(await localStorage.getItem('resId'));
    await localStorage.removeItem('resExTime');
    await localStorage.removeItem('resId');
    await localStorage.removeItem('cart');
    window.dispatchEvent(new Event("storage"));
    navigate('/');
  }

  const removeItem = async (id) => {
    let _cart = localStorage.getItem('cart');
    if (_cart) {
      let cart = JSON.parse(_cart);
      if (cart.length) {
        if (cart.length == 1) {
          clearCart().then(() => {
            fetching();
          });
        } else {

          cart.splice(id, 1);

          let res = {
            plan: { _id: thisEvent.plan._id},
            tickets : [...cart]
          };

          await clearReservation(await localStorage.getItem('resId')).then(async() => {
            await makeReservation(res).then(async(r) => {

              let cartString = JSON.stringify(cart);
              await localStorage.setItem('resId',r._id);
              await localStorage.setItem('cart', cartString);
              await fetching();
            });
          });



        }
      }
    }
  }


  const makeOrder = async (paymentSecret) => {
    setLoading(true);
    let allow = false;
    let resId = await localStorage.getItem('resId');
    let cart = JSON.parse(localStorage.getItem('cart'));
    let newOrder = {
      cart, customer: {
        invoice: order.invoice,
        country: order.country,
        city: order.city,
        zip: order.zip,
        address1: order.address1,
        address2: order.address2,
        firstName: order.firstName,
        lastName: order.lastName,
        email: order.email,
        phoneNumber: order.phoneNumber,
        secret: paymentSecret,
        reservation: resId || null,
        payment: "card"
      }
    };
    await make(newOrder).then((r) => {
      if (r) allow = r;
      setLoading(false);
    });
    return allow;
  }


  useEffect(() => {
    fetching();
  }, []);

  return (
    <React.Fragment>
      {thisEvent?._id ? (
        <>
          <MobileBuyTicket
            total={total}
            clearCart={clearCart}
            thisEvent={thisEvent}
            cart={cart}
            removeItem={removeItem}
            order={order}
            setOrder={setOrder}
            stripePromise={stripePromise}
            submitOrder={makeOrder}
            loading={loading}
            clientSecret={clientSecret}
          />


          <DesktopBuyTicket
            total={total}
            clearCart={clearCart}
            thisEvent={thisEvent}
            cart={cart}
            removeItem={removeItem}
            order={order}
            setOrder={setOrder}
            stripePromise={stripePromise}
            submitOrder={makeOrder}
            loading={loading}
            clientSecret={clientSecret}
          />
        </>

      ) : (<Loading />)}


    </React.Fragment>
  );
};

export default BuyTicket;
