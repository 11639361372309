import { dataGet, dataPost } from "../Services/data.js";

export const addTicket = async (params) => {
  let ticket = {};
  await dataPost("ticket/addTicket", { ...params }).then((response) => {
    ticket = response.content;
  });
  return ticket;
};

export const listTickets = async () => {
  let tickets = [];
  await dataGet("ticket/getAll").then((response) => {
    if (response && response.isSuccess) tickets = response.content;
  });
  return tickets;
};

export const getTicket = async (id) => {
  let ticket = null;
  await dataPost("ticket/getById",{id:id}).then((response) => {
    if (response && response.isSuccess) ticket = response.content;
  });
  return ticket;
};

export const tryTicket = async (id) => {
  let ticket = null;
  await dataPost("ticket/tryById",{id:id}).then((response) => {
    if (response && response.isSuccess) ticket = response.content;
  });
  return ticket;
};

export const use = async (id) => {
  let ticket = false;
  await dataPost("ticket/use",{id:id}).then((response) => {
    if (response && response.isSuccess) ticket = true;
  });
  return ticket;
};

export const updateTicket = async (params) => {
  let ticket = {};
  await dataPost("ticket/updateTicket", { ...params }).then((response) => {
    ticket = response.content;
  });
  return ticket;
};

export const setDefault = async (id) => {
  let ticket = {};
  await dataPost("ticket/setDefault", { id: id }).then((response) => {
    ticket = response.content;
  });
  return ticket;
};

export const deleteTicket = async (id) => {
  let ticket = {};
  await dataPost("ticket/deleteTicket", { _id: id }).then((response) => {
    ticket = response.content;
  });
  return ticket;
};
